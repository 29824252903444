import React from "react";
import { Link } from "react-router-dom";

const TryScreenGenius = () => {
  return (
    <div className="container ScreenGenius_today">
      <div className="row">
        <span className="outer-get-started">
          <h4 className="title">Transform Your Screen Recording <br />Experience <span className="get-started"> – Get Started Today!</span></h4>
          <Link
            className="btn green-vizam-btn ScreenGenius_todaybtn"
            to="https://chromewebstore.google.com/detail/vizam-screenshot-video-ca/eclnikmacpcandpbfjkjgnoicmlpkkdj"
            target="_blank"
          >
            Create, Edit, and Share with Vizam!
          </Link>
        </span>
      </div>
    </div>
  );
};

export default TryScreenGenius;
