import React from 'react';
import contentCreatorIcon from '../../../assets/images/home/content-creator-icon.png';
import educatorTrainersIcon from '../../../assets/images/home/educators-trainers-icon.png';
import bussinessPerfessionalsIcon from '../../../assets/images/home/business-professionals-icon.png';
import gamersIcon from '../../../assets/images/home/gamers-icon.png';
import ContentCard from '../Components/ContentCard';

const WhoShouldUseVizam = () => {
    return (
        <div className='container-fluid who-should-use-vizam'>
            <div className='container'>
                <div className="section_title">
                    <h1>Who Should Use Vizam? </h1>
                </div>
                <div className='content-card-outer'>
                    <ContentCard
                        cardIcon={contentCreatorIcon}
                        cartTitle={"Content Creators"}
                        cardDescription={"Capture, edit, and share your visual content effortlessly, from tutorials to creative videos, all in one tool."}
                    />
                    <ContentCard
                        cardIcon={educatorTrainersIcon}
                        cartTitle={"Educators & Trainers"}
                        cardDescription={"Enhance your lessons with screen recordings and annotations, making learning more interactive and engaging."}
                    />
                    <ContentCard
                        cardIcon={gamersIcon}
                        cartTitle={"Gamers"}
                        cardDescription={"Record your gameplay, add commentary, and share your most epic moments with ease via social platforms."}
                    />
                    <ContentCard
                        cardIcon={bussinessPerfessionalsIcon}
                        cartTitle={"Business Professionals"}
                        cardDescription={"Streamline presentations, tutorials, and feedback with professional-quality screen captures and recordings."}
                    />
                </div>
            </div>
        </div>
    );
};

export default WhoShouldUseVizam;