import React from 'react';

const WorkVizam = () => {
    return (
        <div className='container-fluid how-work-vizam'>
            <div className='container'>
                <div className="section_title">
                    <h1>How Vizam Works</h1>
                </div>
                <div className='youtube-content'>
                    <div className='video-player'>
                        <iframe width="320" height="240" src="https://www.youtube.com/embed/4zTFRA-PiUY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkVizam;