import React from "react";
import recordingIcon from "../../../assets/images/home/recording-icon-green.png";
import editToolIcon from "../../../assets/images/home/edinting-tool-icon-green.png";
import effortlessShareIcon from "../../../assets/images/home/effortless-share-icon-green.png";
import managementIcon from "../../../assets/images/home/management-icon-green.png";
import slideImg1 from "../../../assets/images/home/rec-slider-1.png";
import slideImg2 from "../../../assets/images/home/rec-slider-2.png";
import slideImg3 from "../../../assets/images/home/rec-slider-3.png";
import slideImg4 from "../../../assets/images/home/rec-slider-4.png";
import SmartCard from "../Components/SmartCard";
import { Link } from "react-router-dom";

const ScreenRecordingGame = () => {
  return (
    <div className="container screen_recording_game">
      <div className="container" id="screen-recording-tab">
        <div className="row">
          <div className="section_title vizam-screenshot-smarter">
            <div className="section-heading">
              <h1>Transform Your Screen Recording <br />Experience</h1>
              <p>Engage, educate, and captivate your audience with stunning, professional-quality content.</p>
            </div>
            <Link
              className="btn green-vizam-btn"
              to="https://chromewebstore.google.com/detail/vizam-screenshot-video-ca/eclnikmacpcandpbfjkjgnoicmlpkkdj"
              target="_blank"
            >Try Vizam for Free!</Link>
          </div>
          <div className="col-lg-6 conversations-mid vizam-conversations-mid">
            <SmartCard
              cardIcon={recordingIcon}
              cartTitle={"Record with Flexibility"}
              cardDescription={"Capture entire screen, selected area, or even your camera and current tab, offering unmatched versatility."}
            />
            <SmartCard
              cardIcon={editToolIcon}
              cartTitle={"Professional Editing Tools"}
              cardDescription={"Enhance your recordings with text, shapes, layers, and audio, then trim and edit with precision."}
            />
            <SmartCard
              cardIcon={effortlessShareIcon}
              cartTitle={"Effortless Sharing"}
              cardDescription={"Share your creations seamlessly via link or email, making it easy to impress friends, colleagues, or clients."}
            />
            <SmartCard
              cardIcon={managementIcon}
              cartTitle={"Centralized Management"}
              cardDescription={"Organize and access all your recordings and captures effortlessly through a streamlined dashboard."}
            />
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid vizam-conversations-mid-crousel">
            <div
              className="carousel slide"
              id="carouselScreenRecording"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  className="active"
                  type="button"
                  data-bs-target="#carouselScreenRecording"
                  data-bs-slide-to="0"
                  aria-current="true"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselScreenRecording"
                  data-bs-slide-to="1"
                  className=""
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselScreenRecording"
                  data-bs-slide-to="2"
                  className=""
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselScreenRecording"
                  data-bs-slide-to="3"
                  className=""
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={slideImg1}
                    alt="First slide"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={slideImg2}
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={slideImg3}
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={slideImg4}
                    alt="Fourth slide"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenRecordingGame;
