import React, { useState } from "react";
const PricingPlan = ({
  index,
  plan,
  bxsCheckCircleIcon,
  handleSubscribeUser,
  userData,
}) => {
  let planFeatures = plan?.features;
  planFeatures = JSON.parse(planFeatures);

  const activePlanPrice = userData && userData?.pricing_plan?.price;
  const activePlanStatus = plan?.price < activePlanPrice ? true : false
  const [showMore, setShowMore] = useState(false);

  return (
    <div key={index} className="quix-membership-card">
      <input
        type="radio"
        className="btn-check"
        name="quix-membership"
        id={`quix-monthly-${index}`}
        autoComplete="off"
      />
      <label
        className={`btn quix-membership-btn ${plan?.active && "active-membership"
          }`}
        htmlFor={`quix-monthly-${index}`}
      >
        <span className="quix-plan-price">{plan?.price_string}</span>
        <span className="quix-plan-description">{plan?.description}</span>
        <div className={`quix-plan-features ${showMore ? "expanded" : ""}`}>
          {planFeatures &&
            Object.keys(planFeatures)?.map((feature, index) => {
              return (
                <div className="quix-plan-feature-row" key={index}>
                  <img src={bxsCheckCircleIcon} className="quix-tick-icon" />
                  <span>{planFeatures[feature]?.message}</span>
                </div>
              );
            })}
        </div>
        <button
          className="quix-show-more-btn d-none"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Show Less" : "Show More"}
        </button>
        {plan?.active ? (
          <>
            <button className="btn d-block quix-subscribe-btn quix-active-plan-btn">
              Active
            </button>
          </>
        ) : (
          <div className={`quix-subscribe-btn-outer`}>
            <button
              className={`btn d-block quix-subscribe-btn ${activePlanStatus && 'quix-subscribe-btn-disabled'}`}
              title={activePlanStatus ? 'You cannot downgrade to a lower plan until your existing plan expires.' : ''}
              onClick={() => {
                if (!activePlanStatus) {
                  handleSubscribeUser(plan);
                };
              }}
            >
              Subscribe
            </button>
          </div>
        )}
      </label>
    </div>
  );
};

export default PricingPlan;
