import React from "react";

const SmartCard = ({ cardIcon, cartTitle, cardDescription }) => {
    return (
        <span className="take_your_screen screenshot_smarter">
            <img src={cardIcon} />
            <h5>{cartTitle}</h5>
            <p>{cardDescription}</p>
        </span>
    );
};

SmartCard.defaultProps = {
    cardIcon: "",
    cartTitle: "",
    cardDescription: "",
};

export default SmartCard;
