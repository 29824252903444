import React, { useState } from "react";
import instagramIcon from "../../assets/images/home/instagram.png";
import twitterIcon from "../../assets/images/home/twitter.png";
import youtubeIcon from "../../assets/images/home/youtube-icon.png";
import redditIcon from "../../assets/images/home/reddit-ison.png";
import quixyLogoNew from "../../assets/images/quixy-logo.png";
import footerLogo from "../../assets/images/home/footer-logo.png";
import { Link } from "react-router-dom";
import UserFeedBackModal from "../Modals/UserFeedBackModal";

const Footer = () => {
  const [showFeedbackModal, setFeedbackModal] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="quixy-dashboard-footer-container">
        <div className="row">
          <div className="col-lg-4">
            <span>
              <Link to={'/'}><img className="sitetitle" src={footerLogo} /></Link>
              {/* <p>
                <span>Screenshots &amp; Screen recordings </span>
                <span>with a dash of your unique personality.</span>
              </p> */}
              <p>
                <span>
                  Snap, record, and share your vision with clarity <br />and precision.
                </span>
              </p>
            </span>
          </div>
          <div className="col-lg-4">
            <span>
              <ul>
                <li>
                  {" "}
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="#"
                    onClick={() => setFeedbackModal(!showFeedbackModal)}
                  >
                    Feedback
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy
                  </Link>
                </li>
              </ul>
            </span>
          </div>
          <div className="col-lg-4">
            <span>
              <h5>Contact us</h5>
              {/* <p>Vividminds Technologies Private Limited</p> */}
              <p>
                {" "}
                <a className="support-email" href="mailto:support@vizam.ai">
                  support@vizam.ai
                </a>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="social_icon_copy_right">
        <div className="quixy-dashboard-footer-container">
          <div className="row">
            <div className="col-lg-4">
              <span>
                <a href="https://www.instagram.com/vizamscreentool" target="_blank">
                  <img src={instagramIcon} title="Instagram" />
                </a>
              </span>
              <span>
                <a href="https://x.com/VizamScreenTool" target="_blank">
                  <img src={twitterIcon} title="Twitter(X)" />
                </a>
              </span>
              <span>
                <a href="https://www.youtube.com/@VizamScreenTool" target="_blank">
                  <img src={youtubeIcon} title="Youtube" />
                </a>
              </span>
              <span>
                <a href="https://www.reddit.com/user/VizamScreenTool" target="_blank">
                  <img src={redditIcon} title="Reddit" />
                </a>
              </span>
            </div>
            <div className="col-lg-4">
              {" "}
              <span className="Copyright">
                Copyright ® {currentYear} Company All rights Reserved
              </span>
            </div>
            <div className="col-lg-4">
              <span className="Quixy_Logo">
                Powered by <img src={quixyLogoNew} />
              </span>
            </div>
          </div>
        </div>
      </div>
      {showFeedbackModal && (
        <UserFeedBackModal
          showModal={showFeedbackModal}
          closeModal={setFeedbackModal}
        />
      )}
    </div>
  );
};

export default Footer;
