import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAllPricingPlan } from "../../../redux/actions/managePricingPlan";
import { addMembership } from "../../../redux/actions/manageMembership";
import bxsCheckCircleIcon from "../../../assets/images/home/bxs-check-circle.png";
import MakePayment from "../../../views/profile/components/makePayment";
import PricingPlan from "../../../views/profile/components/pricingPlan";
import { failureMessagePopup, successMessagePopup } from "../../../helper/helper";
import { calculateEndDate } from "../../../helper/helper";
import { localStoragePrefix } from "../../../utils/config";
import LoadingPage from "../../../views/loading-page/LoadingPage";
import { getUserDetailsByID } from "../../../redux/actions/authentication";
import { getGeneralSettingcredentials } from "../../../redux/actions/configServices";

const PricingPlans = ({ userDetails, pricingPlanDetails, loadingPricingPlan }) => {
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const tokenAuth = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  const dispatch = useDispatch();
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [paymentPlan, setpaymentPlan] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState();
  const [generalSettings,setGeneralSettings] = useState(null);
  const editUserData = userDetails?.data && userDetails?.data;
  const customerID = editUserData?.stripe_acc_id && editUserData?.stripe_acc_id;
  const stripeConfig = generalSettings?.stripe && generalSettings?.stripe;
  useEffect(() => {
    if (userID) {
      dispatch(getAllPricingPlan(Number(userID), tokenAuth));
      dispatch(getUserDetailsByID(tokenAuth,()=>{}));
      getGeneralSettingcredentials((res, type) => {
        if (type === 'success') {
          setGeneralSettings(res);
        } else if (type === 'failed') {
          setGeneralSettings(null);
        }
      });
    }
  }, [dispatch, userID, tokenAuth]);

  const pricingPlanList = pricingPlanDetails?.data && pricingPlanDetails?.data;

  const handleSubscribeUser = (plan) => {
    setpaymentPlan(plan);
    setPaymentAmount(plan.price);
    if (plan.price > 0) {
      setShowPaymentPopup(true);
    } else {
      let months = 12;
      let stDate = new Date();
      let endDate = calculateEndDate(months);
      let data = {
        user_id: userID,
        priceplan_id: plan.id,
        start_date: stDate,
        end_date: endDate,
        transaction_id: "",
        access_token: tokenAuth
      };
      dispatch(
        addMembership(data, (type, res) => {
          if (type === 'success') {
            dispatch(getAllPricingPlan(Number(userID), tokenAuth));
          } else if (type === 'fail') {
            failureMessagePopup('Error message', res?.message)
          }
        })
      );
    }
  };

  const handlePaymentResponse = (transactionId) => {
    let months = paymentPlan.duration;
    let stDate = new Date();
    let endDate = calculateEndDate(months);
    let data = {
      user_id: userID,
      priceplan_id: paymentPlan.id,
      start_date: stDate,
      end_date: endDate,
      transaction_id: transactionId,
      access_token: tokenAuth
    }
    dispatch(addMembership(data, (type, res) => {
      if (type === 'success') {
        setShowPaymentPopup(false);
        dispatch(getAllPricingPlan(Number(userID), tokenAuth));
        // userAuthChecker(dispatch, tokenAuth);
        successMessagePopup('Congratulations', 'Plan achieved successfully!')
      } else if (type === 'fail') {
        failureMessagePopup('Error message', res?.message)
      }
    }));
  };


  return (
    <div className="quix-pricing-plans-outer">
      <div className="quix-membership-plans quix-column">
        {loadingPricingPlan && (<LoadingPage opacity={'0.9'} />)}
        <div className="quix-membership-plans-list">
          {pricingPlanList &&
            pricingPlanList?.map((plan, index) => (
              <PricingPlan
                key={index}
                index={index}
                plan={plan}
                bxsCheckCircleIcon={bxsCheckCircleIcon}
                handleSubscribeUser={handleSubscribeUser}
                userData={editUserData}
              />
            ))}
        </div>
        <MakePayment
          showModal={showPaymentPopup}
          paymentAmount={paymentAmount}
          closeModal={setShowPaymentPopup}
          customerID={customerID}
          handlePaymentResponse={handlePaymentResponse}
          stripeConfig={stripeConfig}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pricingPlanDetails: state?.pricingplan?.list,
  userDetails: state?.auth?.user_details,
  loadingPricingPlan: state?.pricingplan?.is_loading
})

export default connect(mapStateToProps, { getAllPricingPlan })(PricingPlans);
