import React, { useState, useRef, useEffect } from "react";
import logoImg from "../../assets/images/home/logo.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import AdSenseAd from "../../components/Adsense/adSenseAd";

const AboutUs = () => {
  // // get dynamically heigth and width for adsense
  // const [calculatedWidth, setCalculatedWidth] = useState(0);
  // const adsenseOuterRef = useRef(null);
  // const middleSectionRef = useRef(null);
  // useEffect(() => {
  //   if (adsenseOuterRef.current && middleSectionRef.current) {
  //     const adsOuterWidth = adsenseOuterRef.current.offsetWidth;
  //     const middleSectionWidth = middleSectionRef.current.offsetWidth;
  //     const calculatedWidth = (adsOuterWidth - middleSectionWidth) / 2;
  //     setCalculatedWidth(calculatedWidth);
  //   }
  // }, []);

  return (
    <div className="about-us-page main-wrapper">
      <div className="header_section">
        <TopBar />
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img className="sitetitle" src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <div className="adsense-outer content-section"> {/*ref={adsenseOuterRef}*/}
        {/* <div className="adsense-bar adsense-left-bar"
          style={{ width: `${calculatedWidth}px`, minWidth: '120px' }}
        >
          <AdSenseAd
            client="ca-pub-4424794709901498"
            slot="8461586205"
            format="vertical"
            style={{ display: 'block', width: `${calculatedWidth}px`, minWidth: '120px' }}
            responsive={true}
          />
        </div>
        <div className="adsense-bar adsense-right-bar"
          style={{ width: `${calculatedWidth}px`, minWidth: '120px' }}
        >
          <AdSenseAd
            client="ca-pub-4424794709901498"
            slot="8461586205"
            format="vertical"
            style={{ display: 'block', width: calculatedWidth, minWidth: '120px' }}
            responsive={true}
          />
        </div> */}
        <div className="container top_container mx-auto"> {/*thankEmoji*/}
          <div className="row">
            <div className="about-us px-4">
              <h5>About Us</h5>
              <p>
                <b>VividMinds Technologies Private Limited </b>
                <is>
                  a Hyderabad based Startup firm founded in the year 2019
                  under Startup India program. Vividminds Technologies
                  Private Limited is into IT product development and{" "}
                </is>
                <b>"Vizam"</b>
                <is>
                  the flagship product of our organization. Vizam
                  is an ultimate solution for all things related to
                  screenshots and screen recordings. Our innovative tool
                  empowers you to effortlessly capture your screen, edit,
                  annotate, and share with ease. With Vizam, you
                  gain access to a user-friendly dashboard tailored to
                  your needs, allowing you to conveniently view, edit, and
                  share your media with the world. Join us and experience
                  the simplicity and efficiency of Vizam today!
                </is>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div >
  );
};

export default AboutUs;
