import React from "react";
import captureIcon from "../../../assets/images/home/capture-image-icon-black.png";
import advanceEditIcon from "../../../assets/images/home/advance-edit-icon-black.png";
import instantShareIcon from "../../../assets/images/home/instant-share-icon-black.png";
import mediaManagementIcon from "../../../assets/images/home/media-management-icon-black.png";
import slideImg1 from "../../../assets/images/home/scr-slide-1.png";
import slideImg2 from "../../../assets/images/home/scr-slide-2.png";
import slideImg3 from "../../../assets/images/home/scr-slide-3.png";
import slideImg4 from "../../../assets/images/home/scr-slide-4.png";
import SmartCard from "../Components/SmartCard";
import { Link } from "react-router-dom";

const ScreenshotSmarter = () => {
  return (
    <div className="container" id="screenshot-tab">
      <div className="row">
        <div className="section_title vizam-screenshot-smarter">
          <div className="section-heading">
            <h1>Screenshot Smarter. Not Harder.</h1>
            <p>Foster Creativity, Share Ideas, and Drive Engaging Conversations with Ease. </p>
          </div>
          <Link
            className="btn black-vizam-btn"
            to="https://chromewebstore.google.com/detail/vizam-screenshot-video-ca/eclnikmacpcandpbfjkjgnoicmlpkkdj"
            target="_blank"
          >Try Vizam for Free!</Link>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid vizam-conversations-mid-crousel">
          <div
            className="carousel slide"
            id="carouselExampleControls"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                className="active"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="0"
                aria-current="true"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="1"
                className=""
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="2"
                className=""
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to="3"
                className=""
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="2000">
                <img
                  className="d-block w-100"
                  src={slideImg1}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img
                  className="d-block w-100"
                  src={slideImg2}
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img
                  className="d-block w-100"
                  src={slideImg3}
                  alt="Third slide"
                />
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img
                  className="d-block w-100"
                  src={slideImg4}
                  alt="Fourth slide"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 conversations-mid vizam-conversations-mid">
          <SmartCard
            cardIcon={captureIcon}
            cartTitle={"Comprehensive Screen Capture Options"}
            cardDescription={"Effortlessly capture your screen in full, select a specific area, or visible part, as per your need."}
          />
          <SmartCard
            cardIcon={advanceEditIcon}
            cartTitle={"Advanced Editing & Annotation Tools"}
            cardDescription={"Edit and enhance your captures with precision. Add text, highlights, blur effects, and more, for professional-quality visuals."}
          />
          <SmartCard
            cardIcon={instantShareIcon}
            cartTitle={"Instant and Seamless Sharing"}
            cardDescription={"Share your creations directly on social platforms or upload to the cloud, generate shareable links, or send via email—all in just a few clicks."}
          />
          <SmartCard
            cardIcon={mediaManagementIcon}
            cartTitle={"Centralized Media Management"}
            cardDescription={"Access and manage all your screenshots through a convenient, easy-to-use dashboard, ensuring everything is organized and at your fingertips"}
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenshotSmarter;
