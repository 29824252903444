import React, { useEffect, useState } from "react";
import loaderIcon from "../../assets/images/light-loader.gif";
import closeIcon from "../../assets/images/quix-close.png";
import { shareByEmailScreenShot } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { shareByEmailVideo } from "../../redux/actions/manageVideos";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";
import Autocomplete from "react-autocomplete";
import { getSuggestedEmail, trackShareEmail } from "../../redux/actions/social-share";

const ShareByEmail = ({ showModal, closeModal, itemType, itemID, userID, accessToken }) => {
  const type = itemType;
  const id = itemID;
  const initialValues = {
    toNameFeedback: "",
    toEmail: "",
    emailMessage: "",
  };

  const [loading, setLoading] = useState(false);
  const [senderDetails, setSenderDetail] = useState(initialValues);
  const [suggestedEmails, setSuggestedEmails] = useState([]);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState();

  useEffect(() => {
    const payload = {
      access_token: accessToken,
      userId: userID
    }
    dispatch(getSuggestedEmail(payload, (type, res) => {
      if (type === 'success') {
        setSuggestedEmails(res);
      } else if (type === 'failed') {
        console.log(res, '--Error--');
      }
    }));
  }, [itemID, accessToken])

  const ShareEmailValidaionSchema = ({ formData, setFormErrors }) => {
    const { emailMessage, toEmail, toNameFeedback } = formData;
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;

    const errors = {
      nameError: "",
      emailError: "",
      emailMessageError: "",
    };

    if (!toNameFeedback?.trim('')) {
      errors.nameError = "First name cannot be empty!";
      valid = false;
    }

    if (!toEmail?.trim('')) {
      errors.emailError = "Email cannot be empty!";
      valid = false;
    } else if (!regex.test(toEmail)) {
      errors.emailError = "Invalid email address!";
      valid = false;
    }

    if (!emailMessage?.trim('')) {
      errors.emailMessageError = "Message cannot be empty!";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const suggestedEmailList = suggestedEmails?.data ? suggestedEmails?.data : [];
  const [inputEmail, getInputEmail] = useState('');

  const handleSelect = (value) => {
    getInputEmail(value);
  };

  const handleTarackEmailShare = (content) => {
    const payload = {
      userId: userID,
      access_token: accessToken,
      content_id: content?.id,
      content_type: content?.type,
      email: content?.email
    }
    dispatch(trackShareEmail(payload, (type, res) => {}));
  }

  const handleSendEmail = () => {
    let senderDetailData = {
      ...senderDetails,
      toEmail: inputEmail
    }
    if (ShareEmailValidaionSchema({ formData: senderDetailData, setFormErrors })) {
      setLoading(true);
      const payload = {
        id: id,
        toname: senderDetails?.toNameFeedback,
        emailId: inputEmail,
        userMessage: senderDetails?.emailMessage,
        userId: userID,
        access_token: accessToken,
      };
      if (type === "image") {
        // Dispatch action to share screenshot via email
        dispatch(
          shareByEmailScreenShot(payload, (type, res) => {
            if (type === "success") {
              setLoading(false);
              closeModal(false);
              successMessagePopup(
                "Success Message!",
                "Shared via email successfully."
              );
              const content = {
                id: payload?.id,
                type: 'screenshot',
                email: payload?.emailId
              }
              handleTarackEmailShare(content);
            } else {
              failureMessagePopup("Field Message!", "Something went wrong.");
              setLoading(false);
              closeModal(false);
            }
          })
        );
      } else {
        // Dispatch action to share video via email
        dispatch(
          shareByEmailVideo(payload, (type, res) => {
            if (type === "success") {
              setLoading(false);
              closeModal(false);
              successMessagePopup(
                "Success Message!",
                "Shared via email successfully."
              );
              const content = {
                id: payload?.id,
                type: 'recording',
                email: payload?.emailId
              }
              handleTarackEmailShare(content);
            } else {
              failureMessagePopup("Field Message!", "Something went wrong.");
              setLoading(false);
              closeModal(false);
            }
          })
        );
      }
    }
  };

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner social-share-email">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Send through Email</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body mt-0">
            <div className="quix-dashboard-popup-body-inner text-start">
              <div className="mb-2">
                <input
                  className="form-control"
                  type="text"
                  name="toNameFeedback"
                  placeholder="Enter Your First Name"
                  onChange={(e) => setSenderDetail({ ...senderDetails, toNameFeedback: e?.target.value })}
                  value={senderDetails?.toNameFeedback}
                  maxLength={255}
                />
                {formErrors?.nameError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.nameError}
                  </p>
                )}
              </div>
              <div className="mb-2 text-start email-item-outer autocomplete-wrapper">
                <Autocomplete
                  value={inputEmail}
                  items={suggestedEmailList?.filter(item => 
                    item?.email && item?.email.toLowerCase().includes(inputEmail.toLowerCase())
                  )}
                  getItemValue={(item) => item?.email}
                  onChange={(e) => getInputEmail(e?.target.value)}
                  onSelect={handleSelect}
                  renderMenu={item => (
                    <div className="dropdown">
                      {item}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className="email-options"
                      key={item?.email}
                      title={item?.email}
                      style={{
                        backgroundColor: isHighlighted ? 'black' : 'white',
                        color: isHighlighted ? 'white' : 'black'
                      }}
                    >
                      {item.email}
                    </div>
                  )}
                  inputProps={{ className: 'form-control', name: "toEmail",placeholder: 'To Email', autocomplete: "off" }}
                />
                {formErrors?.emailError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.emailError}
                  </p>
                )}
              </div>
              <div className="">
                <textarea
                  className="form-control mx-auto"
                  id="email-message"
                  placeholder="Message"
                  maxLength="300"
                  name="emailMessage"
                  onChange={(e) => setSenderDetail({ ...senderDetails, emailMessage: e?.target.value })}
                  value={senderDetails?.emailMessage}
                ></textarea>
                <p className="message-counter"></p>
                {formErrors?.emailMessageError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.emailMessageError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="quix-dashboard-popup-footer mt-0">
            <div className="quix-dashboard-popup-footer-inner position-relative">
              <div className="quix-form-filed text-center">
                <button
                  className="btn form-action-button form-cancel-button"
                  onClick={() => closeModal(false)}
                >
                  cancel
                </button>
                <button
                  className="btn form-action-button form-save-button"
                  onClick={() => handleSendEmail()}
                  disabled={loading}
                >
                  Save
                </button>
                <img
                  style={{ display: !loading ? "none" : "block", top: '9px' }}
                  className="loader-icon"
                  src={loaderIcon}
                />
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareByEmail;
