import React from "react";
import logoImg from "../../../assets/images/home/vizam-logo.png";
import { Link } from "react-router-dom";
import { localStoragePrefix } from "../../../utils/config";

const HeaderSection = () => {
  const isLoginIn = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="header_section new-header">
      <div className="container top_container">
        {/* Navbar */}
        <header className="ScreenGenius-header">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
              <Link className="navbar-brand home-page-logo" to="#">
                <img src={logoImg} height="80" alt="CoolBrand" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav menu-bar">
                <Link className="nav-item nav-link active" to="/" onClick={()=>handleScrollToTop()}>
                  Home
                </Link>
                <a className="nav-item nav-link" href="#screenshot-tab">
                  Capture
                </a>
                <a className="nav-item nav-link" href="#screen-recording-tab">
                  Recorder
                </a>
                <a
                  className="nav-item nav-link"
                  href="#frequently-asked-questions"
                >
                  FAQ
                </a>
              </div>
              <div className="navbar-nav ms-auto">
                {isLoginIn !== null ? (
                  <Link className="nav-item nav-link login" to="/dashboard">
                    Dashboard
                  </Link>
                ) : (
                  <a className="nav-item nav-link login" href="/login">
                    Login
                  </a>
                )}

                <Link
                  className="nav-item nav-link get_started"
                  to="https://chrome.google.com/webstore/detail/screengenius-screenshot-s/eclnikmacpcandpbfjkjgnoicmlpkkdj"
                  target="_blank"
                >
                  Install Vizam for Free
                </Link>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default HeaderSection;
