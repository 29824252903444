import React from "react";
import HeaderSection from "./Sections/HeaderSection";
import ScreenshotSmarter from "./Sections/ScreenshotSmarter";
import ScreenRecordingGame from "./Sections/ScreenRecordingGame";
import WorkVizam from "./Sections/WorkVizam";
import TryScreenGenius from "./Sections/TryScreenGenius";
import FooterSection from "./Sections/FooterSection";
import WhyChooseVizam from "./Sections/WhyChooseVizam";
import "./style.css";
import WhoShouldUseVizam from "./Sections/WhoShouldUseVizam";
import FrequentlyAskedQuestions from "./Sections/FrequentlyAskedQuestions";
import MainBanner from "./Sections/MainBanner";

const Home = () => {
  return (
    <div className="home-page new-home-page" id="root">
      {/* <div className="home-outer container top_container p-0"> */}
      {/* Header Section */}
      <HeaderSection />
      {/* Main Banner Section */}
      <MainBanner />
      {/* How to work vizam */}
      <WorkVizam />
      {/* Why choose vizam */}
      <WhyChooseVizam />
      {/* Screenshot Smarter */}
      <ScreenshotSmarter />
      {/* Take your Screen Recording Game */}
      <ScreenRecordingGame />
      {/* Who Should Use Vizam */}
      <WhoShouldUseVizam />
      {/*  Frequently Asked Questions */}
      <FrequentlyAskedQuestions />
      {/* Try ScreenGenius today */}
      <TryScreenGenius />
      {/* Footer */}
      <FooterSection />
      {/* </div> */}
    </div>
  );
};

export default Home;
