import React from 'react';

const AccordionFAQ = () => {
    return (
        <div className="faq-questions-accordion">
            <div className="accordion" id="accordion_faq">
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_1" aria-expanded="true" aria-controls="collapse_faq_1">
                            What types of screen captures can I make with Vizam?
                        </button>
                    </div>
                    <div id="collapse_faq_1" className="accordion-collapse collapse show" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Vizam allows you to capture full-screen, selected area, tab-specific, or camera-only screenshots, providing flexibility for any need.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_3" aria-expanded="false" aria-controls="collapse_faq_3">
                            Can I edit my screenshots and recordings?
                        </button>
                    </div>
                    <div id="collapse_faq_3" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Yes! Vizam offers a range of editing tools, including text, highlights, shapes, blurring, and trimming options to enhance your captures.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_4" aria-expanded="false" aria-controls="collapse_faq_4">
                            How do I share my content with others?
                        </button>
                    </div>
                    <div id="collapse_faq_4" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>You can easily share your creations by uploading them to the cloud, generating shareable links, or sending them via email.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_5" aria-expanded="false" aria-controls="collapse_faq_5">
                            Is Vizam free to use?
                        </button>
                    </div>
                    <div id="collapse_faq_5" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Yes, Vizam is free to install and use with access to all core features. Additional premium features may be available.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_6" aria-expanded="false" aria-controls="collapse_faq_6">
                            Can I use Vizam for video recording?
                        </button>
                    </div>
                    <div id="collapse_faq_6" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Absolutely! Vizam lets you record full-screen, selected areas, specific tabs, or even just your camera.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_7" aria-expanded="false" aria-controls="collapse_faq_7">
                            Can I record audio while using Vizam?
                        </button>
                    </div>
                    <div id="collapse_faq_7" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Yes, you can record both system audio and microphone input, making it ideal for tutorials, presentations, and gameplay.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_8" aria-expanded="false" aria-controls="collapse_faq_8">
                            How do I manage my screenshots and recordings?
                        </button>
                    </div>
                    <div id="collapse_faq_8" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Vizam provides a centralized dashboard where you can easily view, access, and organize all your captures and recordings.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_9" aria-expanded="false" aria-controls="collapse_faq_9">
                            Is Vizam free? Is there any limit on the screenshots & recordings I do?
                        </button>
                    </div>
                    <div id="collapse_faq_9" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>Yes, it's free as of now, so take advantage of that as much as you can.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_10" aria-expanded="false" aria-controls="collapse_faq_10">
                            Can I be worry-free that my data is safe with Vizam?
                        </button>
                    </div>
                    <div id="collapse_faq_10" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>No doubt about that. Your data safety & security is our top priority. You can relax; your information is in safe hands.</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_faq_11" aria-expanded="false" aria-controls="collapse_faq_11">
                            Till when my generated share links will be valid?
                        </button>
                    </div>
                    <div id="collapse_faq_11" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                        <div className="accordion-body">
                            <p>All your screenshots & screen recordings links are here to stay, as long as you want to keep them in public.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccordionFAQ;