import React from 'react';
import questionIcon from '../../../assets/images/home/faq-question-icon.png'; 

const QuestionCard = () => {
    return (
        <div className='question-card'>
            <div className="question-card-img">
                <img className="question-img" src={questionIcon} alt="faq question" />
            </div>
            <div className='question-card-details'>
                <h5>Do you have more questions?</h5>
                <p>We’re dedicated to making your experience seamless. If you have any questions or need assistance, don’t hesitate to reach out!</p>
                <a href="mailto:support@vizam.ai" className='btn green-vizam-btn'>Email us Now</a>
            </div>
        </div>
    );
};

export default QuestionCard;