import React, { useEffect } from "react";
import LoadingPage from "../../../loading-page/LoadingPage";

const Callback = () => {
    useEffect(() => {
        window.onload = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const paramsObject = {};
            urlParams.forEach((value, key) => {
                paramsObject[key] = value;
            });
            if (Object.keys(paramsObject).length > 0) {
                if (paramsObject) {
                    window.opener?.postMessage(paramsObject, window.location.origin);
                }
            }
        };
    }, []);

    return (
        <div>
            <LoadingPage opacity={'0.9'}/>
        </div>
    );
};

export default Callback;
