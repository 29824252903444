import React from "react";
import AccordionFAQ from "../Components/AccordionFAQ";
import QuestionCard from "../Components/QuestionCard";

const FrequentlyAskedQuestions = () => {
  return (
    <div className="container-fluid frequently_asked_questions">
      <div className="container" id="frequently-asked-questions">
        <div className="row">
          <div className="col-md-4">
            <div className="section_title">
              <h1>Frequently Asked <br />Questions (FAQs) </h1>
            </div>
            <div className="faq-question-section">
              <QuestionCard />
            </div>
          </div>
          <div className="col-md-8 faq-questions-content">
            <AccordionFAQ />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
