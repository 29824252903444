import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MaintenanceMode from '../views/maintenance-mode/maintenance-mode';
import { getGeneralSettingcredentials } from "../redux/actions/configServices";
import LoadingPage from '../views/loading-page/LoadingPage';

const MaintenanceModeRoute = () => {
    const [configObj, setConfigObj] = useState({ maintenance: 0 });
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      getGeneralSettingcredentials((res,type) => {
        if(type === 'success'){
          const maintenanceDetails = res?.maintenance || 0;
          setConfigObj({ maintenance: Number(maintenanceDetails) });
          setLoading(false);
        }else if(type === 'failed'){
          setConfigObj({ maintenance: 0 });
          setLoading(false);
        }
      });
    }, []);
    if (loading) {
      return <LoadingPage  opacity={'0.9'} />;
    }
  const isMaintenance = { token: configObj?.maintenance};
  return isMaintenance?.token === 0 ? <Outlet /> : <MaintenanceMode />;
};

export default MaintenanceModeRoute;