import React from 'react';

const ContentCard = ({ cardIcon, cartTitle, cardDescription }) => {
    return (
        <div className='content-card'>
            <div className='content-card-img'>
                <img src={cardIcon} />
            </div>
            <div className='content-card-details'>
                <h5>{cartTitle}</h5>
                <p>{cardDescription}</p>
            </div>
        </div>
    );
};

ContentCard.defaultProps = {
    cardIcon: "",
    cartTitle: "",
    cardDescription: ""
}

export default ContentCard;