import React from 'react';
import intuitiveIcon from '../../../assets/images/home/effortless-intuitive-icon-green.png';
import professionalIcon from '../../../assets/images/home/professional-icon-green.png';
import multiplePlatformsIcon from '../../../assets/images/home/multiple-platforms-Icon-green.png';
import cloudIcon from '../../../assets/images/home/cloud-green-icon.png';
import ChooseCard from '../Components/ChooseCard';

const WhyChooseVizam = () => {
    return (
        <div className='container-fluid why-choose-vizam'>
            <div className='container'>
                <div className="section_title">
                    <h1>Why Choose Vizam?</h1>
                </div>
                <div className='choose-cards-outer'>
                    <ChooseCard 
                        cardIcon={intuitiveIcon} 
                        cartTitle={"Effortless & Intuitive"} 
                        cardDescription={"Install in seconds, create instantly. Vizam’s sleek, distraction-free interface lets you focus on what matters most."}
                    />
                    <ChooseCard 
                        cardIcon={professionalIcon} 
                        cartTitle={"Professional Branding Made Easy "} 
                        cardDescription={"Add watermark to screenshots and recordings for a polished, professional touch."}
                    />
                    <ChooseCard 
                        cardIcon={multiplePlatformsIcon} 
                        cartTitle={"Flexible Export & Seamless Integration "} 
                        cardDescription={"Save in multiple formats (PNG, JPG, MP4, GIF) and connect effortlessly with Slack, Trello, Notion, Google Drive, and more. "}
                    />
                    <ChooseCard 
                        cardIcon={cloudIcon} 
                        cartTitle={"Cloud Access, Anytime, Anywhere "} 
                        cardDescription={"Sync your captures to the cloud for instant access—never lose an important file again. "}
                    />
                </div>
            </div>
        </div>
    );
};

export default WhyChooseVizam;