import React from 'react';
import vizamBannr from "../../../assets/images/home/vizam-header-banner.png";
import { Link } from "react-router-dom";

const MainBanner = () => {
    return (
        <div div className="container top_container quixy-banner-container" >
            {/* crousel section */}
            <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid quixy-title-section">
                    <div className="left-content">
                        <h2>Capture, Create & <br /> Share Like Never <br /> Before!</h2>
                        <p>
                            Capture, edit, and enhance your visuals with precision— <br />then share instantly!
                        </p>
                        <Link
                            className="btn btn-primary chrome-thisbtn"
                            to="https://chromewebstore.google.com/detail/vizam-screenshot-video-ca/eclnikmacpcandpbfjkjgnoicmlpkkdj"
                            target="_blank"
                        >
                            Install Vizam
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid">
                    <div className="quixy-header-banner">
                        <img src={vizamBannr} />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MainBanner;