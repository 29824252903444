import jQuery from "jquery";
import "jquery-ui";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/resizable";
import boldIcon from "../../assets/images/quix-bold-icon.png";
import italicIcon from "../../assets/images/quix-italic-icon.png";
import alignLeftIcon from "../../assets/images/quix-alignleft-icon.png";
import alignCenterIcon from "../../assets/images/quix-aligncenter-icon.png";
import alignRightIcon from "../../assets/images/quix-alignright-icon.png";
import closeIcon from "../../assets/images/quix-close.png";
import backArrowIcon from "../../assets/images/quix-ve-back-arrow-icon.png";
import undoIcon from "../../assets/images/quix-undo-icon.png";
import redoIcon from "../../assets/images/quix-redo-icon.png";
import resetIcon from "../../assets/images/quix-reset-icon.png";
import fastBackwardIcon from "../../assets/images/quix-ve-fast-backward-icon.png";
import playIcon from "../../assets/images/quix-ve-play-icon.png";
import pauseIcon from "../../assets/images/quix-ve-pause-icon.png";
import fastforwardIcon from "../../assets/images/quix-ve-fast-forward-icon.png";
import textIcon from "../../assets/images/quix-ve-text-icon.png";
import blurIcon from "../../assets/images/quix-ve-blur-icon.png";
import cropIcon from "../../assets/images/quix-ve-crop-icon.png";
import bgMusicIcon from "../../assets/images/quix-ve-bg-musin-icon.png";
import photoUploadIcon from "../../assets/images/quix-ve-photo-upload-icon.png";
import videoUploadIcon from "../../assets/images/video-upload-icon.png";
import speedIcon from "../../assets/images/speed-icon.png";
import insertIcon from "../../assets/images/insert-icon.png";
import effectIcon from "../../assets/images/effect-icon.png";
import cancelEffectIcon from "../../assets/images/cancel-effect.png";
import effectImg from "../../assets/images/effect-card.png";
import closeCircleBlackIcon from "../../assets/images/quix-close-circle.png";
import textToSpeechIcon from "../../assets/images/text-to-speech-icon.png";
import waterMarkIcon from "../../assets/images/watermark.png";
import vioceOverIcon from "../../assets/images/voice-over-icon.png";
import voiceOverImg from "../../assets/images/voice-over.png";
import gifIcon from "../../assets/images/quix-gif-icon.png";
import textSpeechCharManIcon from "../../assets/images/speech-char-man.png";
import textSpeechCharTeacherIcon from "../../assets/images/speech-char-teacher.png";
import textSpeechCharGamerIcon from "../../assets/images/speech-char-gamer.png";
import textSpeechCharGirlIcon from "../../assets/images/speech-char-girl.png";
import EditVideoLoaderImg from "../../assets/images/EditVideoLoader.gif";
import uploadImgIcon from "../../assets/images/upload-icon.png";
import upgradeBanner from "../../assets/images/upgrade-banner.png";
import upgradeIcon from "../../assets/images/quix-upgrade.png";
import { API_URL } from "../../utils/config";
import { calculateIntervals, checkServerURL, sg_interval_HmsToseconds } from "../../helper/helper";
import failureImg from "../../assets/images/quix-failure.png";
import poweredLogoImg from "../../assets/images/quixy-logo.png";

export class VideoEditor {
  constructor(params, getEditedVideoDetails) {
    this.API_SERVER = API_URL;
    this.videoID = params?.videoID;
    this.draggedPlayBar = false;
    this.videoProgressPercent = 0;
    this.totalVideoLength = params?.videoDuration
      ? params?.videoDuration
      : "00:00";
    this.uploadedBGMusic = [];
    this.editVideoGifOBJ = [];
    this.uploadedBGImages = [];
    this.uploadedBGVideos = [];
    this.selectionId = 0;
    this.uploadedInsertItems = [];
    this.videoDimesionsRatioW = 0;
    this.videoDimesionsRatioH = 0;
    this.videoEditOptionItems = [];
    this.prevAnnoType = "";
    this.prevAnnoID = "";
    this.formsSubmitted = 0;
    this.videoEditTextStrings = [];
    this.textIndex = 0;
    this.editInt = 0;
    this.editVideoParams = params;
    this.userDatails = params?.userDetails;
    this.waterMarkObj =
      params?.userDetails?.waterMarkSetting?.length > 0
        ? params?.userDetails?.waterMarkSetting
        : null;
    this.videoDuration = params?.videoDuration
      ? params?.videoDuration
      : "00:00";
    this.videoEffectSlected = null;
    this.audioStream = null;
    this.microphoneNames = [];
    this.recordingChunks = [];
    this.selectedMIC = null;
    this.mediaRecorder = null;
    this.isRecording = false;
    this.mediaAnalyser = null;
    this.audioContext = null;
    this.videoTextConversion = null;
    this.recordingTimeInterval = null;
    this.recordingCurrentTime = Number(0);
    this.getEditedVideoDetails = getEditedVideoDetails;
    this.Mp3audio = "";
    this.voiceOverMP3 = [];
    this.textToSpeechMP3 = [];
    this.insertFormData = [];
    this.waterMarkDataObj = null;
    this.watermarkImage = null;
    this.removeFillerWord = false;
    this.removeSilence = false;
    this.videTrackingTime = [];
    this.pricingPlanFeatures = params?.userDetails?.pricing_plan?.features && JSON.parse(params?.userDetails?.pricing_plan?.features);
    this.isAnnotationsEdited = false;
    this.videoEditorHtml(params);
  }

  addPaidLock = (option, listener = true) => {
    if (option) {
      option.classList.add('paid-disabled')
      if (!option.querySelector('.lock-icon')) {
        const lockIcon = document.createElement('span');
        lockIcon.className = 'lock-icon';
        lockIcon.innerHTML = '🔒';
        option.appendChild(lockIcon);
      }
      const newOption = option.cloneNode(true);
      option.parentNode.replaceChild(newOption, option);
      // Add a new event listener to handle clicks
      if (listener) {
        newOption.addEventListener('click', (event) => {
          if (newOption.classList.contains('paid-disabled')) {
            document.querySelector('div#quix-dashboard-overlay-upgrade').style.display = 'block';
            document.querySelector('div.quix-dashboard-upgrade-popup').style.display = 'block';
            return;
          }
        });
      }
    }
  }

  checkPaidFeatures = () => {
    let waterMark = this.pricingPlanFeatures?.Watermark?.status ?? null;
    let gif = this.pricingPlanFeatures?.Gif?.status ?? null;
    let textToSpeech = this.pricingPlanFeatures?.TextToSpeech?.status ?? null;
    let speechToText = this.pricingPlanFeatures?.SpeechToText?.status ?? null;

    if (!gif) {
      let option = document.querySelector('.video-editing-gif');
      this.addPaidLock(option);
    }
    if (!textToSpeech) {
      let option = document.querySelector('.video-editing-text-to-speech');
      this.addPaidLock(option);
    }
    if (!speechToText) {
      let option = document.querySelector('.speech-to-text');
      option.querySelector('#video-text-conversion').disabled = true;
      this.addPaidLock(option);
    }
    if (!waterMark) {
      let option = document.querySelector('#account-btn');
      option.querySelector('#account').disabled = true;
      this.addPaidLock(option);
    }
  }

  upgradePlanPopup = (element) => {
    let upgradePopupHtml = `
          <div id="quix-dashboard-overlay-upgrade"></div>
          <div id="quix-dashboard-popup" class="quix-update-account-wrapper quix-dashboard-upgrade-popup">
              <div class="quix-dashboard-popup-inner pb-0">
                  <div id="quix-update-account-inner">
                      <div class="quix-signin-row">
                          <img alt="" src="${upgradeBanner}">
                      </div>
                      <div class="quix-signin-row">
                          <h3>Feature not available!</h3>
                      </div>
                      <div class="quix-signin-row">
                          <span>
                              This feature is not available for free users.<br>
                              Please upgrade to enjoy using it.
                          </span>
                      </div>
                      <div class="quix-signin-button-social">
                          <div>
                              <img alt="" src="${upgradeIcon}" />
                              <div>Upgrade</div>
                          </div>
                      </div>
                      <div class="quix-settings-footer">
                          <div class="quix-settings-footer-inner">
                              Powered by <img class="powered_by_logo" src="${poweredLogoImg}">
                          </div>
                      </div>
                  </div>
                  <div class="quix-dashboard-close" id="close-upgrade-popup">
                      <img src="${closeIcon}">
                  </div>
              </div>
          </div>`
    if (element) {
      element.insertAdjacentHTML("beforeend", upgradePopupHtml);
    }
  }

  async videoEditorHtml(videoParam) {
    if(!videoParam?.videoElementID) return;
    let parentElement = document.getElementById(videoParam?.videoElementID);
    var newElement = "";
    newElement +=
      `<div id="video-editor-wrapper" style="background: rgb(0, 0, 0)">
                    <div id="video-editor-inner">
                      <div id="quix-important-message">
                        <span class="quix-important-message">Dear Users, please note: in this Beta version, inactive Data files will be deleted in 180 Days. Thanks for your support.</span>
                      </div>
                      <div id="video-editor-top">
                        <div id="video-editor-back">
                          <a href="/dashboard#videos">
                            <span><img src='${backArrowIcon}' /></span>
                            <span class="video-editor-title">Go to Dashboard</span></a>
                        </div>
                        <div class="quix-power-logo">Powered by <img src="${poweredLogoImg}" /></div>
                        <div id="video-editor-nav">
                          <img class="video-editor-undo" src="${undoIcon}" title="Coming soon"/>
                          <img class="video-editor-redo" src="${redoIcon}" title="Coming soon" />
                          <img class="video-editor-reset" src="${resetIcon}" title="Coming soon" />
                          <button class="editor-save-button">Save</button>
                        </div>
                      </div>
                      <div id="video-editor-content">
                        <div id="video-editor-content-box">
                          <div id="video-editor-content-inner">
                            <video src="${videoParam?.videoURL}"></video>
                          </div>
                          <div class="video-editor-controls">
                            <img class="video-editor-backward" src="${fastBackwardIcon}" action-type="backward" title="Backward" />
                            <img class="video-editor-play" src="${playIcon}" action-type="play" title="Play" />
                            <img class="video-editor-pause" src="${pauseIcon}" action-type="pause" title="Pause" />
                            <img class="video-editor-forward" src="${fastforwardIcon}" action-type="forward" title="Forward" />
                            <div class="video-progress-timer-outer">
                              <span class="video-progress-timer-now">00:00</span>
                              <span class="video-progress-timer-total">/${this.videoDuration}</span>
                            </div>
                          </div>
                          <div class="video-loader">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="video-editor-bottom">
                        <div id="video-editor-editing">
                          <div id="video-editor-editing-options">
                            <div id="video-editor-editing-options-inner">
                              <div class="video-editor-editing-options-col text"></div>
                              <div class="video-editor-editing-options-col blur"></div>
                              <div class="video-editor-editing-options-col image"></div>
                              <div class="video-editor-editing-options-col speed"></div>
                            </div>
                          </div>
                          <div id="video-editor-editing-toolbar">
                            <span class="video-editing-text" action-type="edit-text" title="Text">
                              <img src="${textIcon}" />
                            </span>
                            <span class="video-editing-blur" action-type="edit-blur" title="Blur">
                              <img src="${blurIcon}" />
                            </span>
                            <span class="video-editing-crop" action-type="edit-crop" title="Trim">
                              <img src="${cropIcon}" />
                            </span>
                            <span class="video-editing-effect" action-type="edit-effect" title="Coming Soon" >
                              <img src="${effectIcon}" />
                            </span>
                            <span class="video-editing-music" action-type="edit-music" title="Background Music" >
                              <input id="input-upload-music" type="file" accept="audio/mp3" />
                              <img src="${bgMusicIcon}" />
                            </span>
                            <span class="video-editing-image" action-type="edit-image" title="Upload Image" >
                              <input id="upload-image" type="file" accept="image/jpg,image/png,image/jpeg,image/JPG,image/PNG,image/JPEG" />
                              <img src="${photoUploadIcon}" />
                            </span>
                            <span class="video-editing-video close-sidebar-menu" action-type="edit-video" title="Upload Video" >
                              <input id="upload-video" type="file" accept="video/mp4,video/webm,video/MP4,video/WEBM" />
                              <img src="${videoUploadIcon}" />
                            </span>
                            <span class="video-editing-speed" action-type="edit-speed" title="Playback Speed">
                              <img src="${speedIcon}" />
                            </span>
                            <span class="video-editing-insert" action-type="edit-insert" title="Insert Video/Image" >
                              <input id="upload-insert" type="file" accept="image/jpg,image/png,image/jpeg,video/mp4,video/webm" />
                              <img src="${insertIcon}" />
                            </span>
                            <span class="video-editing-voice-over" action-type="edit-voice-over" title="Voice Over">
                              <img src="${vioceOverIcon}" />
                            </span>
                            <span class="video-editing-text-to-speech" action-type="add-text-speech" title="Text to Speech">
                              <img src="${textToSpeechIcon}" />
                            </span>
                            <span class="video-editing-water-mark close-sidebar-menu" id="video-water-mark" title="Water Mark">
                              <img src="${waterMarkIcon}" />
                            </span>
                            <span class="video-editing-gif" action-type="edit-video-gif" title="Gif">
                              <img src="${gifIcon}" />
                            </span>
                            <div class="checked-edit-options">
                              <div class="video-text-conversion" style="opacity:0.5" title="Coming Soon">
                              <input id="remove-filler-words" disabled type="checkbox" name="removeFillerWords">
                              <label for="remove-filler-words">Remove Filler Words</label>
                            </div>
                              <div class="video-text-conversion" style="opacity:0.5" title="Coming Soon">
                                <input id="remove-silence" disabled type="checkbox" name="removeSilence">
                                <label for="remove-silence">Remove Silence</label>
                              </div>
                              <div class="video-text-conversion speech-to-text" title="Transcript">
                                <input id="video-text-conversion" type="checkbox" name="video-text-conversion">
                                <label for="video-text-conversion">Transcript</label>
                              </div>
                            </div>
                          </div>
                          <div id="video-editor-editing-selection">
                            <div id="video-editor-editing-selection-inner">
                              <div id="video-progress-pointer" style="left:1px">
                                <div id="video-progress-pointer-inner"></div>
                              </div>
                              <div id="video-progress-bar">
                                <div id="video-progress-bar-inner">
                                  <span class="video-progress-start-time">00:00</span>
                                  <span class="video-progress-end-time">${this.videoDuration}</span>
                                  <div class="video-progress-scale">
                                    <div class="video-progress-scale-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div id="video-annotation-blocks">
                                <div id="video-annotation-blocks-inner"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="quix-dashboard-overlay">
                      <div id="quix-dashboard-message">
                        <img src="${EditVideoLoaderImg}" />
                        <span></span>
                      </div>
                    </div>
                    <div id="quix-upgrade-modal"></div>
                    <iframe id="ifrm" name="ifrm" style="display:none;"></iframe>
                  </div>`;
    parentElement.innerHTML = newElement;
    // if (document.querySelector("#ifrm")) {
    //   // document.querySelector("#ifrm").addEventListener("onload",this.getHardWrappedText());
    // }
    var pathname = window.location.pathname;
    // ********************** Effect HTML ********************
    // ********************** Video Effects ********************
    const effectDataObject = [
      {
        id: 1,
        title: "Trending",
        effects: [
          { id: 1, effect_name: "Zoom Lens", effect_img: effectImg },
          { id: 2, effect_name: "Edge Glow", effect_img: effectImg },
          { id: 3, effect_name: "Rolling Film", effect_img: effectImg },
          { id: 4, effect_name: "New Year", effect_img: effectImg },
        ],
      },
      {
        id: 2,
        title: "All",
        effects: [
          { id: 1, effect_name: "Sparkle", effect_img: effectImg },
          { id: 2, effect_name: "Circling", effect_img: effectImg },
        ],
      },
    ];
    let editorElementID = document.getElementById("video-editor-content");
    var videoEffectHtml = "";
    videoEffectHtml += '<div class="quix-video-effect-wrapper">';
    videoEffectHtml += '<div class="quix-video-effect">';
    videoEffectHtml += '<div class="quix-video-effect-header">';
    videoEffectHtml += "<span>Video Effects</span>";
    videoEffectHtml +=
      '<img class="qiux-close-effect" id="qiux-close-effect" src="' +
      cancelEffectIcon +
      '" />';
    videoEffectHtml += "</div>";
    videoEffectHtml += '<div class="quix-video-effects-column-outer">';
    videoEffectHtml += '<div class="quix-video-effects-column">';
    //******************** Effect Column ********************
    effectDataObject?.forEach((el) => {
      videoEffectHtml += '<div class="quix-video-effects-column">';
      videoEffectHtml +=
        '<span class="quix-effect-title">' + el?.title + "</span>";
      videoEffectHtml += '<div class="quix-effect-card-outer">';
      //******************** Effect card ********************
      el?.effects?.forEach((flItem) => {
        videoEffectHtml += '<div class="quix-effect-card">';
        videoEffectHtml +=
          '<div class="quix-effect-card-effect" data-type="' +
          flItem?.effect_name +
          '">';
        videoEffectHtml += '<img src="' + flItem?.effect_img + '" />';
        videoEffectHtml += "</div>";
        videoEffectHtml += "<span>" + flItem?.effect_name + "</span>";
        videoEffectHtml += "</div>";
      });
      //******************** Effect card End ********************
      videoEffectHtml += "</div>";
      videoEffectHtml += "</div>";
    });
    //******************** Effect Column End ********************
    videoEffectHtml += "</div>";
    videoEffectHtml += "</div>";
    videoEffectHtml += "</div>";
    editorElementID.insertAdjacentHTML("beforeend", videoEffectHtml);
    // ******************** Effect HTML End ********************
    // ******************** Popup Outer HTML ********************
    var videoEditorPopupHtml = "";
    videoEditorPopupHtml += '<div class="quix-editor-function-popup-wrapper">';
    videoEditorPopupHtml += '<div class="quix-editor-function-popup">';
    videoEditorPopupHtml += '<div class="quix-editor-function-popup-header">';
    videoEditorPopupHtml += '<span id="popup-title">VoiceOver</span>';
    videoEditorPopupHtml +=
      '<img class="qiux-close-function-popup" id="qiux-close-function-popup" src="' +
      closeCircleBlackIcon +
      '" />';
    videoEditorPopupHtml += "</div>";
    videoEditorPopupHtml +=
      '<div class="quix-editor-function-popup-content"></div>';
    videoEditorPopupHtml += "</div>";
    document
      .querySelector("#video-editor-content")
      .insertAdjacentHTML("beforeend", videoEditorPopupHtml);
    // ******************** Popup Outer HTML End ********************
    var videoPlayer = document.querySelector("#video-editor-content video");
    videoPlayer.onloadeddata = () => {
      var orgW = videoPlayer.videoWidth;
      var orgH = videoPlayer.videoHeight;
      var rendW = jQuery("#video-editor-content-inner video").width();
      var rendH = jQuery("#video-editor-content-inner video").height();
      this.videoDimesionsRatioW =
        Math.round((rendW / orgW + Number.EPSILON) * 100) / 100;
      this.videoDimesionsRatioH =
        Math.round((rendH / orgH + Number.EPSILON) * 100) / 100;
      // ** editVideoAnnotations **
      if (videoParam?.videoDataJSON && videoParam?.videoDataJSON !== "") {
        setTimeout(() => {
          this.editVideoAnnotations(videoParam?.videoDataJSON);
          this.isAnnotationsEdited = true;
        }, 100);
      }
    };

    // ******************** insert popup HTML ********************
    var inertDetailsHtml = `
    <div id="quix-insert-details-popup">
      <div class="quix-insert-form">
        <div class="quix-insert-form-header">
          <span class="insert-heading">Insert Details</span>
        </div>
        <div class="quix-insert-content">
          <form id="insert-form">
            <div class="quix-insert-form-fields">
              <label id="insert-main-title" for="video-size">Video Duration</label>
              <input type="text" name="video_duration" min="0" id="video-size" value="" maxLength="255"/>
              <p id="insert-duration-msg" class="insert-duration-msg m-0">Please insert video duration in seconds.</p>
              <p id="insert-error" style="display:none" class="text-danger m-0">Please enter a valid number (no alphabets or special characters allowed).</p>
            </div>
            <div class="quix-insert-video-size">
              <span>Adjust Postion</span>
              <div class="quix-insert-video-size-innr">
              <div class="quix-insert-form-fields">
                <input type="radio" name="video_position" value="start" id="beginning" />  
                <label for="beginning">In the beginning</label>
              </div>
              <div class="quix-insert-form-fields">
                <input type="radio" checked name="video_position" value="middle" id="middle" />
                <label for="middle">Custom</label>
              </div>
              <div class="quix-insert-form-fields">
                <input type="radio" name="video_position" value="end" id="end" />
                <label for="end">At the end</label>
              </div>
              </div>
            </div>
            <div class="quix-insert-form-fields insert-action-btn">
              <button type="button" class="quix-insert-btn quix-insert-cancel-btn">Cancel</button>
              <button type="button" class="quix-insert-btn quix-inser-save-btn">Save</button>
            </div>
          </form>
        </div>
            <div class="quix-insert-close" id="close-insert">
              <img src="${closeIcon}" />
            </div>
      </div>
    </div>`;
    editorElementID.insertAdjacentHTML("beforeend", inertDetailsHtml);
    // ******************** insert popup HTML End ********************
    jQuery("#quix-insert-details-popup form input").on("change", (e) => {
      jQuery("p#insert-error").css({ display: "none" });
    });
    jQuery(".quix-inser-save-btn").on("click", (e) => {
          jQuery("#quix-insert-details-popup form").each(async (index, form) => {
            let formDataArray = jQuery(form).serializeArray();
            let filedata = this.uploadedInsertItems[this.uploadedInsertItems.length - 1];
            let formData = {};
            formDataArray.forEach((item) => {
              formData[item.name] = item.value;
            });
            const regex = /^[0-9]*\.?[0-9]+$/;
            let insertType = jQuery('#quix-insert-details-popup #insert-form .quix-insert-video-size-innr input:checked').val();
            let insertValue = jQuery('#quix-insert-details-popup #insert-form input#video-size').val();
            if (insertType == "start" || insertType == "end") {
              if (insertValue?.trim('') === "") {
                jQuery("input#video-size").css({ border: "1px solid red" });
                return;
              }
            }
            jQuery("input#video-size").css({ border: "1px solid black" });
            if(!jQuery('.quix-inser-save-btn').hasClass('edit-insert')){
              this.insertMedia(formData,filedata);
              this.addInsertToVideoComp(filedata);
            }else {
              if(jQuery('.int-select-box.insert').hasClass('active')){
                let insertInd = jQuery('.int-select-box.insert.active').attr('insert-index');
                let insertValue = jQuery('input#video-size').val();
                if (insertValue?.trim('') === "") {
                  jQuery("input#video-size").css({ border: "1px solid red" });
                  return;
                }else if (!regex.test(insertValue.trim())) {
                  jQuery("p#insert-error").css({ display: "block" });
                  return;
                }
                this.insertFormData[insertInd].video_duration = insertValue;
                jQuery('.int-select-box.insert.active span.insert-time').text(`${insertValue} sec`);
                jQuery('.int-select-box.insert.active').attr('data-insert-length',insertValue);
                jQuery("#quix-insert-details-popup").css({ display: "none" });
                jQuery("#quix-insert-details-popup form")[0].reset();
                jQuery("input#video-size").prop("disabled", true);
                jQuery("#quix-dashboard-overlay").css({ display: "none" });
                return;
              }
            }
          });
    });
    // *******************************

    jQuery(document).ready(() => {
      jQuery("input#video-size").prop("disabled", true);
      jQuery("input[type=radio]").on("change", (e) => {
        if (
          jQuery(e.currentTarget).val() == "middle" &&
          jQuery(e.currentTarget).is(":checked")
        ) {
          jQuery("input#video-size").prop("disabled", true);
          jQuery("input#video-size").css("border", "1px solid #000");
          jQuery("input#video-size").val("");
          jQuery("#insert-duration-msg").css({ display: "none" });
        } else {
          jQuery("input#video-size").prop("disabled", false);
          jQuery("input#video-size").css("border", "1px solid #000");
          jQuery("#insert-duration-msg").css({ display: "block" });
        }
      });
    });
    let outerUpgrade = document.getElementById('quix-upgrade-modal');
    if (outerUpgrade) {
      this.upgradePlanPopup(outerUpgrade)
    }
    jQuery('#close-upgrade-popup').on('click', () => {
      document.querySelector('div#quix-dashboard-overlay-upgrade').style.display = 'none';
      document.querySelector('div.quix-dashboard-upgrade-popup').style.display = 'none';
    });
    // ******************** Water mark Popup Start ******************
    let waterMarkHtml = "";
    waterMarkHtml += `<div class="video-editor-popup" id="water-mark-popup"> 
                      <div class="video-editor-popup-inner">
                        <div class="video-editor-popup-header">
                          <h5>Water Mark</h5>
                          <img class="qiux-close-function-popup" id="close-video-editor-popup" src="${closeCircleBlackIcon}" />
                        </div>
                        <div class="video-editor-popup-body">
                          <div class="video-editor-popup-content">
                            <div class="manage-water-mark">
                                <div class="water-mark-box" id="account-btn">
                                  <input id="account" type="radio" name="water-mark-option" value="account" />
                                  <label for="account">User Account Setting</label>
                                </div>
                                <div class="water-mark-box" id="custom-btn">
                                  <input id="custom" type="radio" name="water-mark-option" value="custom" />
                                  <label for="custom">Custom Watermark Setting</label>
                                </div>
                            </div>
                            <div class="water-mark-settings" id="water-mark-settings">
                              <div class="quix-water-video-content">
                                <p class="quix-heading-con">Upload video watermark</p>
                                <div class="col-md-12 mb-3 text-start">
                                  <div class="quix-item-uploader-box d-flex">
                                    <div class="quix-upoloaded-img" style="display: none;">
                                      <img class="qiux-close-function-popup" id="close-uploaded-img" src="${closeCircleBlackIcon}" />
                                      <img id="uploaded-watermark" src="">
                                    </div>
                                    <div class="quix-uploader w-100">
                                      <div class="quix-item-uploader">
                                        <input id="input-file-upload" class="quix-upload-input" accept="image/jpg,image/jpeg,image/png" type="file" name="image_videos" value="" />
                                        <img src="${uploadImgIcon}"/>
                                      </div>
                                      <span style="font-size: 12px; display: block;">Choose only this format(JPG,JPEG,PNG)</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mb-1 text-start">
                                <div class="col-md-12 mb-1 text-start">
                                  <label class="col-form-label">Water mark text</label>
                                  <input type="text" name="text_videos" class="form-control" value="" maxLength="255" />
                                </div>
                                <div class="col-md-8 mb-1 text-start">
                                  <label class="col-form-label">Position</label>
                                  <select class="form-control form-select" name="position" id="position">
                                    <option value="LT">Left Top</option>
                                    <option value="LB">Left Bottom</option>
                                    <option value="RT">Right Top</option>
                                    <option value="RB">Right Bottom</option>
                                    <option value="C">Center</option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label class="col-form-label">Text Color</label>
                                  <input type="color" name="textColor" class="form-control color-picker-box" value="#000000" />
                                  </div>
                                  <div class="col-md-12 range-input-filed">
                                  <label class="col-form-label">Font Size</label>
                                  <input type="range" min="12" max="72" name="fontSize" class="form-range border-0" value="12">
                                  <div class="range-badge">12</div>
                                  </div>
                                  <div class="col-md-12 range-input-filed">
                                  <label class="col-form-label">Transparency (Range between 0 to 1)</label>
                                  <input type="range" class="form-range border-0" name="transparency" min="0" max="1" step="0.1"  value="0.5" />
                                  <div class="range-badge">0.5</div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6 mb-1 text-start range-input-filed">
                                    <label class="col-form-label">Margin from border X</label>
                                    <input type="range" name="marginBorderX" id="marginBorderX" class="form-range border-0" value="0" min="0" max="300" />
                                    <div class="range-badge">10</div>
                                  </div>
                                  <div class="col-md-6 mb-1 text-start range-input-filed">
                                    <label class="col-form-label">Margin from border Y</label>
                                    <input type="range" name="marginBorderY" id="marginBorderY" class="form-range border-0" value="0" min="0" max="300" />
                                    <div class="range-badge">10</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-4">
                              <button class="btn form-action-button form-cancel-button">Cancel</button>
                              <button class="btn form-action-button form-save-button">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`;

    document
      .querySelector("#video-editor-content")
      .insertAdjacentHTML("beforeend", waterMarkHtml);

    jQuery(document).ready(() => {
      let positionInput = jQuery("#position");
      let marginBorderXInput = jQuery("#marginBorderX");
      let marginBorderYInput = jQuery("#marginBorderY");
      function handleUpdateBorderPostion() {
        let postionValue = positionInput.val();
        if (postionValue === "C") {
          jQuery(marginBorderXInput).prop("disabled", true);
          jQuery(marginBorderYInput).prop("disabled", true);
          jQuery(marginBorderXInput).val(0);
          jQuery(marginBorderYInput).val(0);
        } else {
          jQuery(marginBorderXInput).prop("disabled", false);
          jQuery(marginBorderYInput).prop("disabled", false);
        }
      }

      handleUpdateBorderPostion();
      positionInput.on("change", () => {
        handleUpdateBorderPostion();
      });

      //  On hover show range
      jQuery(".form-range").on("change", () => {
        let value = jQuery(".form-range").val();
        jQuery(".form-range").next(".range-badge").text(value);
      });
      jQuery(".form-range").mousemove(function (event) {
        var value = jQuery(this).val();
        jQuery(this).next(".range-badge").text(value);
        var rangeBadge = jQuery(this).next(".range-badge");
        var rangeBadgeWidth = rangeBadge.outerWidth();
        var cursorOffset = event.pageX - jQuery(this).offset().left;
        var leftOffset = cursorOffset - rangeBadgeWidth / 2;
        rangeBadge.css({
          left: leftOffset + "px",
        });
      });

      let waterMarkSettings = jQuery(".water-mark-settings");
      const toggleWaterMarkSettings = () => {
        let account = document.querySelector('input#account').disabled;
        if (account) {
          document.querySelector('input#custom').checked = true;
          document.querySelector('#account-btn').addEventListener('click', () => {
            document.querySelector('#quix-dashboard-overlay').style.display = 'none';
            document.querySelector('#water-mark-popup').style.display = 'none';
          });
        }
        if (jQuery("#water-mark-popup input#custom").prop("checked")) {
          waterMarkSettings.show();
        } else {
          waterMarkSettings.hide();
          if (!this.waterMarkObj) {
            jQuery("#water-mark-popup input#account").prop("disabled", true);
            jQuery(".manage-water-mark #account-btn").addClass("disabled-btn");
            jQuery("#water-mark-popup input#custom").prop("checked", true);
            waterMarkSettings.show();
          } else {
            jQuery("#water-mark-popup input#account").prop("checked", true);
          }
        }
      };
      toggleWaterMarkSettings();
      jQuery(".manage-water-mark input").on("change", toggleWaterMarkSettings);

      jQuery("#water-mark-settings input#input-file-upload").on(
        "change",
        (e) => {
          let fileFormat = ["image/jpg", "image/jpeg", "image/png"];
          var file = e.target.files[0];
          let fileType = file?.type;
          this.watermarkImage = file;
          if (fileFormat.indexOf(fileType) > -1) {
            jQuery(".quix-upoloaded-img").css({ display: "block" });
            var reader = new FileReader();
            reader.onload = function (e) {
              jQuery(".quix-upoloaded-img img#uploaded-watermark").attr(
                "src",
                e.target.result
              );
            };
            reader.readAsDataURL(file);
          } else {
            this.failureMessagePopup(
              "Unsupported Image Format",
              "Please upload a JPG, JPEG or PNG file."
            );
          }
          jQuery("#water-mark-settings input#input-file-upload").val("");
        }
      );

      jQuery("#water-mark-settings img#close-uploaded-img").on("click", (e) => {
        this.watermarkImage = null;
        jQuery(".quix-upoloaded-img").css({ display: "none" });
        jQuery(".quix-upoloaded-img img#uploaded-watermark").attr("src", "");
      });

      jQuery("#water-mark-popup .form-save-button").on("click", () => {
        let watermarkText = jQuery("input[name='text_videos']").val();
        let fontSize = jQuery("input[name='fontSize']").val();
        let textColor = jQuery("input[name='textColor']").val();
        let transparency = jQuery("input[name='transparency']").val();
        let position = jQuery("select[name='position']").val();
        let marginX = jQuery("input[name='marginBorderX']").val();
        let marginY = jQuery("input[name='marginBorderY']").val();

        var payload = {};
        if (jQuery("#water-mark-popup input#account").prop("checked")) {
          payload.type = "account";
        } else if (jQuery("#water-mark-popup input#custom").prop("checked")) {
          payload = {
            image_videos: !this.watermarkImage
              ? null
              : this.watermarkImage?.name,
            text_videos: {
              fontSize: fontSize,
              fontColor: textColor,
              text: watermarkText,
            },
            videos_transparency: transparency,
            position_videos: { position: position, x: marginX, y: marginY },
            type: "custom",
          };
        }
        this.waterMarkDataObj = payload;
        jQuery("#water-mark-popup").hide();
        jQuery("#quix-dashboard-overlay").css({ display: "none" });
      });
    });

    jQuery("#water-mark-popup .form-cancel-button").on("click", () => {
      jQuery("#water-mark-popup").hide();
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
      jQuery(".quix-upoloaded-img").css({ display: "none" });
      jQuery(".quix-upoloaded-img img#uploaded-watermark").attr("src", "");
    });

    jQuery("#water-mark-popup #close-video-editor-popup").on("click", () => {
      jQuery("#water-mark-popup").hide();
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
      jQuery(".quix-upoloaded-img").css({ display: "none" });
      jQuery(".quix-upoloaded-img img#uploaded-watermark").attr("src", "");
    });

    // ******************** Water mark Popup End ********************

    // video loader
    jQuery(videoPlayer).on("loadstart", () => {
      jQuery(".video-loader").css("display", "block");
    });
    jQuery(videoPlayer).on("canplaythrough", function () {
      jQuery(".video-loader").css("display", "none");
    });

    videoPlayer.onstarted = () => {
      jQuery(".video-editor-play").hide();
      jQuery(".video-editor-pause").show();
      this.positionPlayBarVideoProgress(0);
    };
    videoPlayer.ontimeupdate = (event) => {
      var currTime = this.sg_secondsToHms(event.srcElement.currentTime);
      if (currTime != "") {
        this.positionPlayBarVideoProgress(event.srcElement.currentTime);
        if (document.querySelector(".video-progress-timer-now")) {
          document.querySelector(".video-progress-timer-now").innerText =
            currTime;
        }
      }
    };
    videoPlayer.onended = () => {
      jQuery(".video-editor-play").show();
      jQuery(".video-editor-pause").hide();
      this.positionPlayBarVideoProgress(
        this.sg_HmsToseconds(this.totalVideoLength)
      );
    };
    this.totalVideoLength = this.videoDuration;
    document.querySelector(".video-progress-end-time").innerText =
      this.videoDuration;
    document.querySelector(".video-progress-timer-total").innerText =
      "/ " + this.videoDuration;
    // ******************** Save Edited Video ********************
    jQuery(".editor-save-button").unbind("click");
    jQuery(".editor-save-button").on("click", (e) => {
      this.videoEditTextStrings = [];
      this.videTrackingTime.push({
        id: 1,
        click: 'When Save button clicked',
        time: new Date().toLocaleString("en-GB", { timeZone: "Europe/Paris", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }) + ':' + new Date().getMilliseconds().toString().padStart(3, '0')
      })
      e.preventDefault();
      this.formsSubmitted = 0;
      this.setVideoEditOptions(this.prevAnnoID, this.prevAnnoType);
      this.displayEditMessage(
        'Please wait. It might take around a while<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>'
      );
      let formOuterEl = jQuery(`.editor-text-box`);
      if (formOuterEl) {
        formOuterEl.find("form.text-editor-form").each((index, formEl) => {
          let parentEl = jQuery(formEl).parent("div");
          let elemId = parentEl.attr("id");
          let selectedInd = parentEl.attr("data-index");
          if (formEl) {
            jQuery(formEl).one("submit", (event) => {
              event.stopPropagation();
              this.processForm(event, selectedInd, elemId);
            });
            jQuery(formEl).trigger("submit");
          }
        });
      }
      // this.getTextStrings();
      this.editVideo();
    });

    jQuery("#video-progress-pointer").unbind("mousedown");
    jQuery("#video-progress-pointer").on("mousedown", (e) => {
      this.draggedPlayBar = true;
      jQuery("#video-annotation-blocks-inner").css({
        "pointer-events": "none",
      });
    });

    jQuery("#video-editor-editing-selection-inner").unbind("mousemove");
    jQuery("#video-editor-editing-selection-inner").on("mousemove", (e) => {
      this.positionPlayBar(e);
    });

    jQuery("#video-editor-editing-selection-inner").unbind("mouseup");
    jQuery("#video-editor-editing-selection-inner").on("mouseup", (e) => {
      this.draggedPlayBar = false;
      jQuery("#video-annotation-blocks-inner").css({
        "pointer-events": "all",
      });
    });

    jQuery(".video-editor-controls img").unbind("click");
    jQuery(".video-editor-controls img").on("click", (e) => {
      var video = document.querySelector("#video-editor-content video");
      var actionType = jQuery(e.currentTarget).attr("action-type");
      if (video.readyState >= 3) {
        this.videoPlayerActions(video, actionType);
      }
    });

    jQuery("#video-editor-editing-toolbar span").unbind("click");
    jQuery("#video-editor-editing-toolbar span").on("click", (e) => {
      var actionType = jQuery(e.currentTarget).attr("action-type");
      this.videoEditOptions(actionType);
    });

    jQuery("#upload-image").unbind("change");
    jQuery("#upload-image").on("change", (e) => {
      var filedata = e.target.files[0];
      var fileType = filedata?.type;
      if (fileType?.includes("image")) {
        this.uploadedBGImages.push(e.target.files[0]);
        this.addImageToVideoComp(filedata);
        jQuery("#upload-image").val("");
      }
      // else if (fileType?.includes('video')) {
      //   this.uploadedBGVideos.push(e.target.files[0]);
      //   this.addVideoToVideoComp(filedata);
      //   jQuery("#upload-image").val('')
      // }
    });

    jQuery("#upload-video").unbind("change");
    jQuery("#upload-video").on("change", (e) => {
      var filedata = e.target.files[0];
      var fileType = filedata?.type;
      if (fileType?.includes("video")) {
        this.uploadedBGVideos.push(e.target.files[0]);
        this.addVideoToVideoComp(filedata);
        jQuery("#upload-video").val("");
      }
    });

    jQuery("#remove-filler-words").on("change", (e) => {
      let ckecked = e.target.checked;
      this.removeFillerWord = ckecked;
    });

    jQuery("#remove-silence").on("change", (e) => {
      let ckecked = e.target.checked;
      this.removeSilence = ckecked;
    });

    jQuery("#upload-insert").unbind("change");
    jQuery("#upload-insert").on("change", (e) => {
      jQuery(".int-select-box.insert").removeClass("active");
      var filedata = e.target.files[0];
      this.uploadedInsertItems.push(e.target.files[0]);
      jQuery("#quix-insert-details-popup").css({ display: "block" });
      jQuery("#quix-dashboard-overlay").css({ display: "block" });
      jQuery(".quix-insert-video-size").css({ display: "block" });
      jQuery("input#video-size").val("");
      jQuery(`input#video-size`).prop("disabled", true);
      jQuery(`input[value=middle]`).prop("checked", true);
      if (filedata?.type.includes("image")) {
        jQuery("#insert-form #insert-main-title").text("Image Duration");
        jQuery("#insert-form #insert-duration-msg").text(
          "Please insert image duration in seconds."
        );
        if (jQuery("input[value=middle]").is(":checked")) {
          jQuery("#insert-duration-msg").css({ display: "none" });
        }
      } else {
        jQuery("#insert-form #insert-main-title").text("Video Duration");
        jQuery("#insert-form #insert-duration-msg").text(
          "Please insert video duration in seconds."
        );
        if (jQuery("input[value=middle]").is(":checked")) {
          jQuery("#insert-duration-msg").css({ display: "none" });
        }
      }
      jQuery("#upload-insert").val("");
      // this.addInsertToVideoComp(filedata);
    });

    jQuery("#quix-insert-details-popup .quix-insert-cancel-btn").on(
      "click",
      () => {
        jQuery("#quix-insert-details-popup").css({ display: "none" });
        jQuery("#quix-dashboard-overlay").css({ display: "none" });
      }
    );

    jQuery("#close-insert").on("click", () => {
      jQuery("#quix-insert-details-popup").css({ display: "none" });
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
    });

    jQuery("#video-annotation-blocks-inner").unbind("click");
    jQuery("#video-annotation-blocks-inner").on("click", (e) => {
      if (e.target === e.currentTarget) {
        this.positionPlayBarClick(e);
      }
    });

    jQuery("#video-water-mark").on("click", () => {
      jQuery("#water-mark-popup").toggle();
      jQuery("#quix-dashboard-overlay").css({ display: "block" });
    });

    // ******************** Editor Fffect Close ********************
    // document.querySelector('.qiux-close-effect').addEventListener('click',function() {
    //   document.querySelector('.quix-video-effect-wrapper').classList.remove('quix-video-effect-active')
    // })
    jQuery(".qiux-close-effect").on("click", (e) => {
      jQuery(".quix-video-effect-wrapper").removeClass(
        "quix-video-effect-active"
      );
    });

    jQuery(".quix-effect-card-effect").on("click", (e) => {
      jQuery(".quix-effect-card-effect").removeClass("active-effect");
      jQuery(e.currentTarget).addClass("active-effect");
      jQuery(e.currentTarget).attr("data-type");
      this.videoEffectSlected = jQuery(e.currentTarget).attr("data-type");
    });
    // ******************** Function Popup Close ********************
    jQuery(".qiux-close-function-popup").on("click", (e) => {
      jQuery(".quix-editor-function-popup-wrapper").removeClass(
        "quix-active-function-popup"
      );
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
    });
    jQuery("#input-upload-music").unbind("change");
    jQuery("#input-upload-music").on("change", (e) => {
      this.uploadedBGMusic.push(e.target.files[0]);
      setTimeout(() => {
        jQuery("#quix-dashboard-overlay").hide();
        jQuery("#quix-dashboard-popup").hide();
        this.intervalSelectorBox("bgMusic", bgMusicIcon);
      }, 1200);
      jQuery(e.currentTarget).val("");
    });

    jQuery(".video-text-conversion #video-text-conversion").on(
      "change",
      (e) => {
        let status = jQuery(e.currentTarget).prop("checked");
        this.videoTextConversion = status;
      }
    );
    this.checkPaidFeatures();
    // ******************** Get Media Device  Onload Page ********************
    await this.getMediaDevice();
  }

  insertMedia(formData,filedata){
    const regex = /^[0-9]*\.?[0-9]+$/;
    let videoTime = jQuery("input#video-size").val();
    jQuery("p#insert-error").css({ display: "none" });
    var fileName = formData?.val;
    this.insertFormData.push({
      ...formData,
      val: fileName,
    });
    if (formData?.video_position !== "middle") {
      if (!regex.test(videoTime.trim())) {
        jQuery("p#insert-error").css({ display: "block" });
        return;
      }
    }
    if (formData?.video_position == "start" || formData?.video_position == "end") {
      if (formData?.video_duration == "") {
        jQuery("input#video-size").css({ border: "1px solid red" });
        return;
      }
    }
    if (jQuery(".int-select-box.insert").hasClass("active")) {
      var activeInsertDiv = jQuery(".int-select-box.insert.active");
      let index = activeInsertDiv.attr("insert-index");
      let value = jQuery("input#video-size").val();
      this.insertFormData[index].video_duration = value;
      jQuery(`.int-select-box.insert[insert-index="${index}"] span.insert-time`).text(`${value} sec`);
      jQuery(`.int-select-box.insert[insert-index="${index}"]`).attr("data-insert-length",value);
      jQuery("#quix-insert-details-popup").css({ display: "none" });
      jQuery("#quix-insert-details-popup form")[0].reset();
      jQuery("input#video-size").prop("disabled", true);
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
      return true;
    }
    jQuery("#quix-insert-details-popup").css({ display: "none" });
    jQuery("#quix-insert-details-popup form")[0].reset();
    jQuery("input#video-size").prop("disabled", true);
    jQuery("#quix-dashboard-overlay").css({ display: "none" });
  }

  displayTextToSpeechContent() {
    let popupTitle = document.querySelector(
      ".quix-editor-function-popup span#popup-title"
    );
    let textSpeechParentElement = document.querySelector(
      ".quix-editor-function-popup-content"
    );
    popupTitle.innerText = "Text to Speech";
    var textSpeechContent = "";

    textSpeechContent +=
      '<div class="quix-text-speech-content">\n\
                            <div class="quix-text-speech-details">\n\
                              <div class="quix-text-speech-input-box">\n\
                                <label>Input Text</label>\n\
                                <textarea type="text" id="quix-text-speech" placeholder="Enter" rows="5" name="text speech"></textarea>\n\
                              </div>\n\
                              <div class="quix-speech-creator text-start">\n\
                                <span class="quix-text-speech-title">Voice</span>\n\
                                <div class="text-speech-charater active-voice" action-type="MAN" name="en-US-Casual-K">\n\
                                  <img src="' +
      textSpeechCharManIcon +
      '" />\n\
                                </div>\n\
                                <div class="text-speech-charater" action-type="FEMALE" name="en-US-Neural2-C">\n\
                                  <img src="' +
      textSpeechCharTeacherIcon +
      '" />\n\
                                </div>\n\
                                <div class="text-speech-charater" action-type="MAN" name="en-US-Neural2-I">\n\
                                  <img src="' +
      textSpeechCharGamerIcon +
      '" />\n\
                                </div>\n\
                                <div class="text-speech-charater" action-type="FEMALE" name="en-US-Journey-F">\n\
                                  <img src="' +
      textSpeechCharGirlIcon +
      '" />\n\
                                </div>\n\
                              </div>\n\
                                <button id="convert-btn-textspeech">Convert</button>\n\
                                <div class="quix-text-to-voice-footer quix-voice-footer">\n\
                                  <div class="quix-voice-pay-box">\n\
                                    <div class="quix-audio-player">\n\
                                      <img id="quix-play-audio" src="' +
      playIcon +
      '" action-type="play-audio"/>\n\
                                      <img  id="quix-pause-audio" src="' +
      pauseIcon +
      '" action-type="pause-audio"/>\n\
                                    </div>\n\
                                  </div>\n\
                                  <button class="quix-save-voice">Save</button><button class="quix-cancel-voice">Cancel</button>\n\
                                </div>\n\
                            </div>\n\
                          </div>';
    textSpeechParentElement.innerHTML = textSpeechContent;

    var textToSpeechOBJ = { voice: "MALE", name: "en-US-Casual-K" };
    jQuery(".quix-text-speech-details textarea").on("change", (e) => {
      let value = jQuery(e.currentTarget).val();
      textToSpeechOBJ = { ...textToSpeechOBJ, text: value };
    });
    jQuery(".quix-speech-creator .text-speech-charater").on("click", (e) => {
      jQuery(".text-speech-charater").removeClass("active-voice");
      var actionType = jQuery(e.currentTarget).attr("action-type");
      var name = jQuery(e.currentTarget).attr("name");
      jQuery(e.currentTarget).addClass("active-voice");
      textToSpeechOBJ = { ...textToSpeechOBJ, voice: actionType, name: name };
    });
    jQuery(".quix-text-speech-details button#convert-btn-textspeech").on(
      "click",
      (e) => {
        this.getTextToSpeech(textToSpeechOBJ);
      }
    );
    jQuery(
      ".quix-text-to-voice-footer .quix-voice-pay-box .quix-audio-player img"
    ).on("click", (e) => {
      let textSpeechActionType = jQuery(e.currentTarget).attr("action-type");
      var textSpeechAudioData = this.textToSpeechMP3?.[0];
      if (textSpeechActionType == "play-audio") {
        this.playerAudio(
          jQuery(e.currentTarget).parents(".quix-audio-player"),
          textSpeechAudioData
        );
      } else if (textSpeechActionType == "pause-audio") {
        this.stopAudio(jQuery(e.currentTarget).parents(".quix-audio-player"));
      }
    });
    jQuery(".quix-text-to-voice-footer button.quix-save-voice").on(
      "click",
      (e) => {
        if (this.textToSpeechMP3.length > 0) {
          this.uploadedBGMusic.push(this.textToSpeechMP3[0]);
          jQuery(".quix-editor-function-popup-wrapper").removeClass(
            "quix-active-function-popup"
          );
          jQuery("#quix-dashboard-overlay").css({ display: "none" });
          this.stopAudio(jQuery(".quix-audio-player"));
          setTimeout(() => {
            this.intervalSelectorBox("bgMusic", textToSpeechIcon);
          }, 1200);
          this.textToSpeechMP3 = [];
        }
      }
    );

    jQuery(".quix-text-to-voice-footer button.quix-cancel-voice").on(
      "click",
      (e) => {
        this.textToSpeechMP3 = [];
        jQuery(".quix-text-to-voice-footer").css({ display: "none" });
        this.stopAudio(jQuery(".quix-audio-player"));
        jQuery("#quix-dashboard-overlay").css({ display: "none" });
      }
    );
  }

  displayVoiceContent() {
    let voiceParentElement = document.querySelector(
      ".quix-editor-function-popup-content"
    );
    let popupTitle = document.querySelector(
      ".quix-editor-function-popup span#popup-title"
    );
    popupTitle.innerText = "VoiceOver";
    var voiceContent = "";
    voiceContent += '<div class="quix-voice-over-content">';
    voiceContent += '<div class="quix-voice-over-details">';
    voiceContent += '<div class="quix-voice-over-rec">';
    voiceContent +=
      '<img class="quix-voice-over-img" title="Start Recording" src="' +
      voiceOverImg +
      '">';
    voiceContent += "</div>";
    voiceContent += '<div class="quix-voice-over-recording-box">';
    voiceContent += '<span id="quix-voice-over-timer">00:00</span>';
    voiceContent += '<p id="quix-voice-over-status">Recording not started</p>';
    voiceContent += "</div>";
    voiceContent += "</div>";
    voiceContent += '<div class="quix-voice-waves">';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += '<div class="quix-rec-wave"></div>';
    voiceContent += "</div>";
    voiceContent += '<div class="quix-voice-input-device-content">';
    voiceContent += '<div class="quix-voice-input-device">';
    voiceContent += '<label for="select-device">Input Device</label>';
    voiceContent += '<select id="select-device">';
    voiceContent +=
      this.microphoneNames.length > 0
        ? this.microphoneNames
          .map(
            (device) =>
              '<option value="' +
              device?.groupId +
              '">' +
              device?.label +
              "</option>"
          )
          .join("")
        : '<option style="display:none">Device not found!</option><option disabled>Device not found!</option>';
    voiceContent += "</select>";
    voiceContent += "</div>";
    voiceContent += '<div class="quix-voice-volume">';
    voiceContent += '<label for="volume">Volume</label>';
    voiceContent += '<div class="quix-voice-rage-box">';
    voiceContent += '<div class="quix-voice-rage-outer">';
    voiceContent +=
      '<input id="volume" class="quix-valume-range" value="15" type="range" min="0" max="100">';
    voiceContent += "</div>";
    voiceContent += '<div class="quix-valume-input">';
    voiceContent +=
      '<input type="number" value="15" min="0" max="100" pattern="d*">';
    voiceContent += "</div>";
    voiceContent += "</div>";
    voiceContent += "</div>";
    voiceContent += `<div class="quix-voice-footer">
        <div class="quix-voice-pay-box">
            <div class="quix-audio-player">
              <img id="quix-play-audio" src="${playIcon}" title="Play" action-type="play-audio"/><img  id="quix-pause-audio" src="${pauseIcon}" title="Pause" action-type="pause-audio"/>
            </div>
        </div>
        <button class="quix-save-voice">Save</button><button class="quix-cancel-voice">Cancel</button>
      </div>`;
    voiceContent += "</div>";
    voiceContent += "</div>";
    voiceParentElement.innerHTML = voiceContent;
    // ******************** Get Intial Value of Device ********************
    if (this.microphoneNames.length > 0) {
      jQuery("#select-device").val(jQuery("#select-device option:first").val());
      this.selectedMIC = jQuery("#select-device option:first").val();
    }

    jQuery(".quix-voice-input-device select").on("change", async (e) => {
      this.selectedMIC = jQuery(e.currentTarget).val();
      await this.getMediaDevice();
    });

    jQuery(".quix-voice-over-rec").on("click", async (e) => {
      if (this.selectedMIC !== null) {
        jQuery(".quix-voice-over-rec").toggleClass("started-voice-rec");
        if (jQuery(".quix-voice-over-rec").hasClass("started-voice-rec")) {
          this.startRecording();
          // jQuery("#quix-voice-over-status").text("Recording started");
          // this.recordingTimeInterval = setInterval(
          //   () => this.recordingTimeHandler(),
          //   1000
          // );
        } else {
          this.stopRecording();
          // jQuery("#quix-voice-over-status").text("Recording not started");
          // clearInterval(this.recordingTimeInterval);
          // this.recordingCurrentTime = Number(0);
          // jQuery("#quix-voice-over-timer").text(
          //   this.sg_secondsToHms(this.recordingCurrentTime)
          // );
        }
      } else {
        alert("Please select Microphone!");
      }
    });
    // Add popup player
    jQuery(".quix-voice-pay-box .quix-audio-player img").on("click", (e) => {
      let voiceActionType = jQuery(e.currentTarget).attr("action-type");
      var voiceAudioData = this.voiceOverMP3[0];
      if (voiceActionType == "play-audio") {
        this.playerAudio(
          jQuery(e.currentTarget).parents(".quix-audio-player"),
          voiceAudioData
        );
      } else if (voiceActionType == "pause-audio") {
        this.stopAudio(jQuery(e.currentTarget).parents(".quix-audio-player"));
      }
    });
    jQuery(".quix-voice-footer button.quix-save-voice").on("click", (e) => {
      if (this.voiceOverMP3.length > 0) {
        this.uploadedBGMusic.push(this.voiceOverMP3[0]);
        this.recordingChunks = [];
        jQuery(".quix-editor-function-popup-wrapper").removeClass(
          "quix-active-function-popup"
        );
        this.stopAudio(jQuery(".quix-audio-player"));
        setTimeout(() => {
          this.intervalSelectorBox("bgMusic", vioceOverIcon);
        }, 1200);
      }
      jQuery("#quix-dashboard-overlay").css({ display: "none" });
    });

    jQuery(".quix-voice-footer button.quix-cancel-voice").on("click", (e) => {
      this.recordingChunks = [];
      this.voiceOverMP3 = [];
      jQuery(".quix-voice-footer").css({ display: "none" });
      jQuery(".quix-voice-waves .quix-rec-wave").removeClass(
        "quix-active-wave"
      );
      this.stopAudio(jQuery(".quix-audio-player"));
    });
  }

  recordingTimeHandler() {
    let recTimeElement = jQuery("#quix-voice-over-timer");
    this.recordingCurrentTime++;
    recTimeElement.text(this.sg_secondsToHms(this.recordingCurrentTime));
  }

  getTextStrings() {
    var forms = jQuery("form");
    if (forms.length > 0) {
      // forms.on("submit", function(event) {
      // event.preventDefault();
      // let formdata = jQuery(this).serialize();
      // console.log(formdata,"-formdata-");
      // // Submit the form using AJAX or perform other actions here if needed
      // // For example, you can use jQuery's AJAX method:
      // jQuery.ajax({
      //     url: jQuery(this).attr("action"),
      //     method: jQuery(this).attr("method"),
      //     data: jQuery(this).serialize(),
      //     success: function(response) {
      //         console.log(response);
      //     },
      //     error: function(xhr, status, error) {
      //         console.log(error);
      //     }
      // });
      // });
      jQuery(forms[0]).submit();
      // forms.each(function() {
      //   jQuery(this).trigger("submit");
      // });
    } else {
      this.editVideo();
    }
  }

  getUploadedItemDuration(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        if (file?.type?.startsWith("video")) {
          var mediaElement = document.createElement("video");
          mediaElement.onloadedmetadata = () => {
            resolve(Math.floor(mediaElement.duration));
          };
          mediaElement.onerror = () => {
            reject("Failed to load media metadata");
          };
          const fileUrl = URL.createObjectURL(file);
          mediaElement.src = fileUrl;
        } else {
          const reader = new FileReader();
          reader.onloadend = function () {
            const arrayBuffer = reader.result;
            const audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            audioContext.decodeAudioData(
              arrayBuffer,
              function (buffer) {
                const durationInSeconds = buffer.duration;
                resolve(Math.floor(durationInSeconds));
              },
              function (error) {
                reject("Error decoding audio: " + error);
              }
            );
          };
          reader.readAsArrayBuffer(file);
        }
      } else {
        reject("No file provided");
      }
    });
  }

  getTextToSpeech(textOBJ) {
    this.textToSpeechMP3 = [];
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      content: textOBJ?.text,
      gender: textOBJ?.voice,
      name: textOBJ?.name,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    jQuery("#convert-btn-textspeech").prop("disabled", true);
    fetch("" + this.API_SERVER + "/videos/text-to-speech", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status) {
          let url = this.API_SERVER + result.url;
          this.fetchMP3AndConvertToBlob(url).then((blob) => {
            if (blob) {
              let bloburl = URL.createObjectURL(blob);
              const file = new File([blob], "text-to-speech.mp3", {
                type: "audio/mp3",
              });
              if (file) {
                this.textToSpeechMP3.push(file);
                jQuery(".quix-text-to-voice-footer").css({ display: "block" });
                jQuery("#convert-btn-textspeech").prop("disabled", false);
              }
            } else {
              console.error("Failed to fetch MP3 or convert to blob.");
              jQuery("#convert-btn-textspeech").prop("disabled", false);
            }
          });
        }
      })
      .catch((error) => console.error(error));
  }

  async getMediaDevice() {
    try {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const audioInputDevices = devices.filter(
            (device) => device.kind === "audioinput"
          );
          const uniqueGroupIds = new Set();
          audioInputDevices.forEach((device) => {
            if (!uniqueGroupIds.has(device.groupId)) {
              this.microphoneNames.push(device);
              uniqueGroupIds.add(device.groupId);
            }
          });
        })
        .catch((error) => {
          console.error("Error enumerating devices:", error);
        });
    } catch (error) {
      console.error("Error getting microphone names:", error);
    }
  }

  async startRecording() {
    this.voiceOverMP3 = [];
    this.audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        mandatory: {
          deviceId: this.selectedMIC,
        },
      },
      video: false,
    });
    this.audioContext = new AudioContext();
    this.mediaAnalyser = this.audioContext.createAnalyser();
    const microphone = this.audioContext.createMediaStreamSource(
      this.audioStream
    );
    microphone.connect(this.mediaAnalyser);

    this.mediaAnalyser.fftSize = 256;
    const bufferLength = this.mediaAnalyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    this.mediaAnalyser.getByteFrequencyData(dataArray);

    if (this.audioStream) {
      this.mediaRecorder = new MediaRecorder(this.audioStream);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordingChunks.push(event.data);
        }
      };
      this.mediaRecorder.onstart = () => {
        jQuery("#quix-voice-over-status").text("Recording started");
        jQuery(".quix-voice-over-rec img.quix-voice-over-img").prop(
          "title",
          "Stop Recording"
        );
        this.recordingTimeInterval = setInterval(
          () => this.recordingTimeHandler(),
          1000
        );
      };
      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordingChunks, { type: "audio/mp3" });
        // ******************** Download Recording For testing ********************
        // downloadRecording(blob)
        jQuery("#quix-voice-over-status").text("Recording not started");
        jQuery(".quix-voice-over-rec img.quix-voice-over-img").prop(
          "title",
          "Start Recording"
        );
        clearInterval(this.recordingTimeInterval);
        this.recordingCurrentTime = Number(0);
        jQuery("#quix-voice-over-timer").text(
          this.sg_secondsToHms(this.recordingCurrentTime)
        );
        const file = new File([blob], "voiceover.mp3", { type: "audio/mp3" });
        if (file) {
          this.voiceOverMP3.push(file);
          jQuery(".quix-voice-footer").css({ display: "block" });
        }
        this.recordingChunks = [];
        this.mediaRecorder.stop();
        this.audioStream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
        // this.uploadedBGMusic.push(file);
        // this.recordingChunks = [];
        // jQuery(".quix-editor-function-popup-wrapper").removeClass(
        //   "quix-active-function-popup"
        // );
        // setTimeout(() => {
        //   this.intervalSelectorBox("bgMusic", "voiceover");
        // }, 1200);
      };

      this.isRecording = true;
      this.visualize(dataArray);
      this.mediaRecorder.start();
    }
    // ******************** Download Recording ********************
    function downloadRecording(blob) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "recorded_audio.mp3";
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.recordingChunks = [];
      this.isRecording = false;
      this.mediaRecorder.stop();
      this.mediaAnalyser.disconnect();
      this.audioContext.close().then(() => { });
    }
  }

  visualize(dataArray) {
    if (!this.isRecording) return;
    this.mediaAnalyser.getByteFrequencyData(dataArray);
    const wavesList = document.querySelectorAll(
      ".quix-voice-waves .quix-rec-wave"
    );
    wavesList.forEach((bar, index) => {
      const value = dataArray[index];
      const isActiveWave = value > 0;
      if (isActiveWave) {
        bar.classList.add("quix-active-wave");
      } else {
        bar.classList.remove("quix-active-wave");
      }
    });

    requestAnimationFrame(() => this.visualize(dataArray));
  }

  displayEditMessage(message) {
    this.editInt = 0;
    var intEditMessage = setInterval(function () {
      var messageArr = [
        'Uploading Assests<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
        'Analyzing Annotations<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
        'Processing Video Conversion<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
        'Almost There<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
        'Preparing Video<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
        'Finishing with video editing<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>',
      ];
      var message = messageArr[this.editInt];
      jQuery("#quix-dashboard-message span").html(message);
      this.editInt++;
      if (this.editInt == 6) {
        clearInterval(intEditMessage);
      }
    }, 20000);
    jQuery("#quix-dashboard-overlay").show();
    jQuery("#quix-dashboard-message span").html(message);
    jQuery("#quix-dashboard-message").show();
  }
  hideMessage() {
    jQuery("#quix-dashboard-overlay").hide();
    jQuery("#quix-dashboard-message span").html("");
    jQuery("#quix-dashboard-message").hide();
  }
  sg_secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h : "00";
    var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
    var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
    return mDisplay + ":" + sDisplay;
  }

  sg_HmsToseconds(hms) {
    var a = hms.split(":");
    var seconds = +a[0] * 60 + +a[1];
    return seconds;
  }

  sg_secondsToHhMmSs = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h : "00";
    var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
    var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
    return hDisplay + ":" + mDisplay + ":" + sDisplay;
  };

  getVideoDimension() {
    let video = jQuery("#video-editor-content-inner video")[0];
    var width = video.videoWidth;
    var height = video.videoHeight;
    return { width: width, height: height };
  }

  calculateTotal(data) {
    let total = 0;
    data.forEach(item => {
      const points = item.points;
      if (points.length) {
        total += parseInt(points.length);
      }
      if (points.start !== undefined && points.end !== undefined) {
        total += (points.end - points.start);
      }
    });
    return total;
  }


  editVideo() {
    var vpath = jQuery("#video-editor-content video").attr("src");
    var videoSpeed = jQuery("#quix-video-speed").val();
    var videoDimension = this.getVideoDimension();
    var isTranscript = jQuery("#video-text-conversion").is(":checked");
    var cropIntervals = [];
    var blurIntervals = [];
    var textIntervals = [];
    var imageIntervals = [];
    var videoIntervals = [];
    var bgMusicIntervals = [];
    var insertIntervals = [];
    var editGifIntervals = [];
    var cropPoints = jQuery(".int-select-box.crop");
    var blurPoints = jQuery(".int-select-box.blur");
    var textPoints = jQuery(".int-select-box.text");
    var imagePoints = jQuery(".int-select-box.image");
    var videoPoints = jQuery(".int-select-box.upload-video");
    var bgMusicPoints = jQuery(".int-select-box.bgMusic");
    var insertPoints = jQuery(".int-select-box.insert");
    var editGifPoints = jQuery(".int-select-box.gif");
    if (cropPoints.length > 0) {
      cropIntervals = this.calculateCropIntervals(cropPoints);
    }
    if (blurPoints.length > 0) {
      blurIntervals = this.calculatePostData(blurPoints, "blur");
    }
    if (textPoints.length > 0) {
      textIntervals = this.calculatePostData(textPoints, "text");
    }
    if (imagePoints.length > 0) {
      imageIntervals = this.calculatePostData(imagePoints, "image");
    }
    if (videoPoints.length > 0) {
      videoIntervals = this.calculatePostData(videoPoints, "video");
    }
    if (bgMusicPoints.length > 0) {
      bgMusicIntervals = this.calculatePostData(bgMusicPoints, "bgMusic");
    }
    if (insertPoints.length > 0) {
      insertIntervals = this.calculatePostData(insertPoints, "insert");
    }
    if (editGifPoints.length > 0) {
      editGifIntervals = this.calculatePostData(editGifPoints, "gif");
    }
    const editPayload = {
      videoID : this.videoID,
      crop_points: cropIntervals,
      blur_points: blurIntervals,
      text_points: textIntervals,
      image_points: imageIntervals,
      video_points: videoIntervals,
      bgMusic_points: bgMusicIntervals,
      insert_points: insertIntervals,
      video_speed: videoSpeed ? videoSpeed : "1.0",
      video_dimension: videoDimension,
      transcript: isTranscript ? isTranscript : false,
      video_effects: "zoom-lens",
      bgMusic: this.uploadedBGMusic,
      bgImage: this.uploadedBGImages,
      bgVideo: this.uploadedBGVideos,
      videoTextConversion: this.videoTextConversion,
      videoEffect: this.videoEffectSlected,
      insertData: this.uploadedInsertItems,
      waterMark: this.waterMarkDataObj,
      watermarkImage: this.watermarkImage,
      gif_points: editGifIntervals,
      remove_filler_words: this.removeFillerWord,
      remove_silence: this.removeSilence,
      video_tacked: this.videTrackingTime
    };
    this.videTrackingTime = [];
    if(editPayload.crop_points.length > 0){
      const points = editPayload.crop_points;
      const insertPoints = editPayload.insert_points;
      const lastPoint = points[points.length - 1];
      let videoLength = this.sg_HmsToseconds(this.videoDuration) + this.calculateTotal(insertPoints);
      lastPoint.end = `${this.sg_secondsToHhMmSs(videoLength)}`;
    }
    this.getEditedVideoDetails(editPayload, (res) => {
      if (res) {
        this.hideMessage();
      } else if (res === null) {
        this.hideMessage();
      }
    });
  }

  calculatePostData(points, type) {
    var blocks = [];
    var compsData = [];
    var boxWidth = parseInt(
      jQuery("#video-editor-editing-selection-inner").width()
    );
    if (type == "text") {
      blocks = jQuery(".editor-text-box");
    } else if (type == "blur") {
      blocks = jQuery(".editor-blur-box");
    } else if (type == "image") {
      blocks = jQuery(".editor-video-image");
    } else if (type == "video") {
      blocks = jQuery(".editor-video-video");
    } else if (type == "gif") {
      blocks = jQuery(".gif");
    }
    blocks = Array.from(blocks);
    if (blocks.length > 0) {
      blocks = blocks.reverse();
    }
    var textInt = 0;
    let textArray = this.videoEditTextStrings.reverse();
    for (let i = points.length - 1; i >= 0; i--) {
      var compsOBJ = "";
      if (type == "text") {
        // ******************** Text editor ************************
        // var dataVal = this.videoEditTextStrings[i].text;
        // let index = jQuery(blocks[i]).attr("text-index");
        // var dataVal = this.videoEditTextStrings[parseInt(index) - 1]?.text;
        var dataVal = textArray[i]?.text;
        // var dataVal = jQuery(blocks[i]).find("#textarea").val();
        // ******************** Text editor ************************
        // var dataVal = jQuery(blocks[i]).find("#textarea").text(); //this.videoEditTextStrings[textInt];
        // var dataVal = jQuery(blocks[i]).find("#textarea").html();
        // dataVal = dataVal.replace(/<\/div>/g, '<br>').trim();
        textInt++;
        var dataValW = jQuery(blocks[i]).find("#textarea").width();
        var dataValH = jQuery(blocks[i]).find("#textarea").height();
        var dataOffsetI = jQuery(blocks[i]).offset();
        var dataOffsetO = jQuery("#video-editor-content-inner").offset();
        var dataOffsetL = dataOffsetI.left - dataOffsetO.left;
        var dataOffsetT = dataOffsetI.top - dataOffsetO.top;
        var fontSize = jQuery(blocks[i])
          .find("textarea#textarea")
          .css("font-size");
        var fontColor = jQuery(blocks[i])
          .find("textarea#textarea")
          .css("color");
        var fontAlign = jQuery(blocks[i])
          .find("textarea#textarea")
          .css("text-align");
        var fontStyle = jQuery(blocks[i])
          .find("textarea#textarea")
          .css("font-style");
        if (fontStyle == "normal") {
          fontStyle = false;
        } else {
          fontStyle = true;
        }
        var fontWeight = jQuery(blocks[i])
          .find("textarea#textarea")
          .css("font-weight");
        if (fontWeight == "400") {
          fontWeight = false;
        } else {
          fontWeight = true;
        }
        if (dataVal != "") {
          compsOBJ = {
            val: dataVal,
            w: parseInt(dataValW / this.videoDimesionsRatioW),
            h: parseInt(dataValH / this.videoDimesionsRatioH),
            x: parseInt(dataOffsetL / this.videoDimesionsRatioW),
            y: parseInt(dataOffsetT / this.videoDimesionsRatioH),
            fontSize: parseInt(fontSize),
            fontColor: this.rgbToHex(fontColor),
            fontAlign: fontAlign,
            fontStyle: fontStyle,
            fontWeight: fontWeight,
          };
        }
      } else if (type == "blur") {
        var dataValW = jQuery(blocks[i]).find(".editor-blur-box-inner").width();
        var dataValH = jQuery(blocks[i])
          .find(".editor-blur-box-inner")
          .height();
        var dataVal = "";
        var dataOffsetI = jQuery(blocks[i]).offset();
        var dataOffsetO = jQuery("#video-editor-content-inner").offset();
        var dataOffsetL = dataOffsetI.left - dataOffsetO.left;
        var dataOffsetT = dataOffsetI.top - dataOffsetO.top;
        var intensity = jQuery(blocks[i])
          .find(".editor-blur-box-inner")
          .css("backdrop-filter");
        var intensityOBj = intensity.split("blur(");
        var intensityOBj = intensityOBj[1].split(")");
        intensity = intensityOBj[0];
        compsOBJ = {
          val: dataVal,
          w: parseInt(dataValW / this.videoDimesionsRatioW),
          h: parseInt(dataValH / this.videoDimesionsRatioH),
          x: parseInt(dataOffsetL / this.videoDimesionsRatioW),
          y: parseInt(dataOffsetT / this.videoDimesionsRatioH),
          intensity: parseInt(intensity),
        };
      } else if (type == "image") {
        var ponitsIndex = jQuery(points[i]).attr("data-bg-index");
        var fileName = jQuery(blocks[i]).find("img").attr("src");
        fileName = encodeURIComponent(
          "public/" + fileName.substring(fileName.indexOf("/upload"))
        );
        if (this.uploadedBGImages[ponitsIndex] !== undefined) {
          fileName = this.uploadedBGImages[ponitsIndex]["name"];
        }
        var dataValW = jQuery(blocks[i]).find("img").width();
        var dataValH = jQuery(blocks[i]).find("img").height();
        var dataOffsetI = jQuery(blocks[i]).offset();
        var dataOffsetO = jQuery("#video-editor-content-inner").offset();
        var dataOffsetL = dataOffsetI.left - dataOffsetO.left;
        var dataOffsetT = dataOffsetI.top - dataOffsetO.top;
        var opacity = jQuery(blocks[i]).find("img").css("opacity");
        var shadow = jQuery(blocks[i]).css("box-shadow");
        compsOBJ = {
          val: fileName,
          w: parseInt(dataValW / this.videoDimesionsRatioW),
          h: parseInt(dataValH / this.videoDimesionsRatioH),
          x: parseInt(dataOffsetL / this.videoDimesionsRatioW),
          y: parseInt(dataOffsetT / this.videoDimesionsRatioH),
          opacity: opacity,
          shadow: shadow,
        };
      } else if (type == "video") {
        var ponitsIndex = jQuery(points[i]).attr("data-bg-index");
        var fileName = jQuery(blocks[i]).find("video source").attr("src");
        fileName = encodeURIComponent(
          "public/" + fileName.substring(fileName.indexOf("/upload"))
        );
        if (this.uploadedBGVideos[ponitsIndex] !== undefined) {
          fileName = this.uploadedBGVideos[ponitsIndex]["name"];
        }
        var dataValW = jQuery(blocks[i]).find("video").width();
        var dataValH = jQuery(blocks[i]).find("video").height();
        var dataOffsetI = jQuery(blocks[i]).offset();
        var dataOffsetO = jQuery("#video-editor-content-inner").offset();
        var dataOffsetL = dataOffsetI.left - dataOffsetO.left;
        var dataOffsetT = dataOffsetI.top - dataOffsetO.top;
        var opacity = jQuery(blocks[i]).find("video").css("opacity");
        // var shadow = jQuery(blocks[i]).css("box-shadow");
        compsOBJ = {
          val: fileName,
          w: parseInt(dataValW / this.videoDimesionsRatioW),
          h: parseInt(dataValH / this.videoDimesionsRatioH),
          x: parseInt(dataOffsetL / this.videoDimesionsRatioW),
          y: parseInt(dataOffsetT / this.videoDimesionsRatioH),
          opacity: opacity,
          // shadow: shadow,
        };
      } else if (type == "bgMusic") {
        var ponitsIndex = jQuery(points[i]).attr("data-bg-index");
        var fileName = this.uploadedBGMusic[ponitsIndex]["name"];
        compsOBJ = {
          val: fileName,
        };
      } else if (type == "gif") {
        var gifPointsArr = [];
        var ponitsOffset = jQuery(points[i]).offset();
        var leftPos = ponitsOffset.left;
        var outerOffset = jQuery("#video-editor-editing-selection").offset();
        var outerOffsetLeft = parseInt(outerOffset.left);
        leftPos = leftPos - outerOffsetLeft;
        var widthCord = jQuery(points[i]).width();
        var startPoint = this.calculateVideoProgress(leftPos);
        var endPoint = this.calculateVideoProgress(leftPos + widthCord);
        var endPointF = parseInt(endPoint);
        var startPointF = parseInt(startPoint);
        compsOBJ = { gifTime: 5 };
      } else if (type == "insert") {
        var ponitsIndex = jQuery(points[i]).attr("insert-index");
        var fileName = this.uploadedInsertItems[ponitsIndex]["name"];
        compsOBJ = {
          val: fileName,
        };
      }
      var ponitsOffset = jQuery(points[i]).offset();
      var leftPos = ponitsOffset.left;
      var outerOffset = jQuery("#video-editor-editing-selection").offset();
      var outerOffsetLeft = parseInt(outerOffset.left);
      leftPos = leftPos - outerOffsetLeft;
      var widthCord = jQuery(points[i]).width();
      var startPoint = this.calculateVideoProgress(leftPos);
      var endPoint = this.calculateVideoProgress(leftPos + widthCord);
      var endPointF = parseInt(endPoint);
      var startPointF = parseInt(startPoint);
      if (compsOBJ != "") {
        var pointsObj = { start: startPointF, end: endPointF };
        if (type == "insert") {
          var pointObj = jQuery(".int-select-box.insert");
          if (
            jQuery(pointObj[i]).hasClass("insert-start") ||
            jQuery(pointObj[i]).hasClass("insert-end")
          ) {
            var insertType = jQuery(pointObj[i]).attr("data-insert-type");
            var insertLength = jQuery(pointObj[i]).attr("data-insert-length");
            pointsObj = { type: insertType, length: insertLength };
          }
        }
        var combOBJ = { data: compsOBJ, points: pointsObj };
        compsData.push(combOBJ);
      }
    }
    return compsData;
  }

  calculateCropIntervals(points) {
    var cropPointsArr = [];
    for (let i = 0; i < points.length; i++) {
      var ponitsOffset = jQuery(points[i]).offset();
      var leftPos = ponitsOffset.left;
      var outerOffset = jQuery("#video-editor-editing-selection").offset();
      var outerOffsetLeft = parseInt(outerOffset.left);
      leftPos = leftPos - outerOffsetLeft;
      var widthCord = jQuery(points[i]).width();
      var startPoint = this.calculateVideoProgress(leftPos);
      var endPoint = this.calculateVideoProgress(leftPos + widthCord);
      var endPointF = parseInt(endPoint);
      var startPointF = parseInt(startPoint);
      var cropPointsObj = { start: startPointF, end: endPointF };
      cropPointsArr.push(cropPointsObj);
    }
    var remainingSlots = {};
    for (let i = 0; i < cropPointsArr.length; i++) {
      var slotStart = cropPointsArr[i].start;
      var slotEnd = cropPointsArr[i].end;
      remainingSlots[slotStart] = slotEnd;
    }
    var cropPoints = this.findRemainingSlots(remainingSlots);
    return cropPoints;
  }

  findRemainingSlots(remainingSlots) {
    var i = 0;
    var finalRes = [];
    var lastStart = 0;
    var lastEnd = 0;
    var obJLen = Object.keys(remainingSlots).length;
    var finalOBJ = "";
    for (let obj of Object.keys(remainingSlots)) {
      finalOBJ = "";
      if (i == 0 && obj > 0) {
        var start = this.sg_secondsToHms(0);
        var end = this.sg_secondsToHms(obj);
        var interval = obj;
        if (interval > 0) {
          finalOBJ = {
            start: "00:" + start,
            end: "00:" + end,
            interval: interval,
          };
        }
      } else {
        var start = this.sg_secondsToHms(lastEnd + 1);
        var end = this.sg_secondsToHms(obj);
        var interval = obj - lastEnd;
        if (interval > 0) {
          finalOBJ = {
            start: "00:" + start,
            end: "00:" + end,
            interval: interval,
          };
        }
      }
      lastStart = obj;
      lastEnd = remainingSlots[obj];
      if (finalOBJ != "") {
        finalRes.push(finalOBJ);
      }
      if (i == obJLen - 1) {
        var start = this.sg_secondsToHms(lastEnd + 1);
        var end = this.totalVideoLength;
        var interval = this.sg_HmsToseconds(this.totalVideoLength) - lastEnd;
        if (interval > 0) {
          finalOBJ = {
            start: "00:" + start,
            end: "00:" + end,
            interval: interval,
          };
          finalRes.push(finalOBJ);
        }
      }
      i++;
    }
    return finalRes;
  }

  positionPlayBarClick(e) {
    jQuery("#video-progress-pointer").css({ left: e.offsetX });
    var progressShould = this.calculateVideoProgress(e.offsetX);
    var video = document.querySelector("#video-editor-content video");
    video.currentTime = progressShould;
  }

  positionPlayBar(e) {
    if (this.draggedPlayBar && e.offsetX > 5) {
      jQuery("#video-progress-pointer").css({ left: e.offsetX });
      var progressShould = this.calculateVideoProgress(e.offsetX);
      var video = document.querySelector("#video-editor-content video");
      video.currentTime = progressShould;
    }
  }

  positionPlayBarVideoProgress(currTime) {
    var totalVideoLengthSeconds = this.sg_HmsToseconds(this.totalVideoLength);
    var boxWidth = parseInt(
      jQuery("#video-editor-editing-selection-inner").width()
    );
    var progressPercent = (currTime / totalVideoLengthSeconds) * 100;
    var leftPos = parseInt((boxWidth / 100) * progressPercent);
    if (!this.draggedPlayBar) {
      jQuery("#video-progress-pointer").css({ left: leftPos + 1 });
    }
  }

  positionPlayBarVideoProgressEdit(startTime, endTime, callback) {
    var totalVideoLengthSeconds = this.sg_HmsToseconds(this.totalVideoLength);
    if (endTime > totalVideoLengthSeconds) {
      endTime = totalVideoLengthSeconds;
    }
    var boxWidth = parseInt(
      jQuery("#video-editor-editing-selection-inner").width()
    );
    startTime = startTime;
    endTime = endTime;
    var progressPercent = (startTime / totalVideoLengthSeconds) * 100;
    var progressPercent2 = (endTime / totalVideoLengthSeconds) * 100;
    var leftPos = parseInt((boxWidth / 100) * progressPercent);
    var rightPos = parseInt((boxWidth / 100) * progressPercent2);
    var wid = rightPos - leftPos;
    callback({ left: leftPos, width: wid });
  }

  calculateVideoProgress(leftPos) {
    var boxWidth = parseInt(
      jQuery("#video-editor-editing-selection-inner").width()
    );
    var progressPercent = (leftPos / boxWidth) * 100;
    if (progressPercent < 0) {
      progressPercent = 0;
    }
    if (progressPercent > 100) {
      progressPercent = 100;
    }
    this.videoProgressPercent = progressPercent;
    var seconds = this.sg_HmsToseconds(this.totalVideoLength);
    return (seconds * progressPercent) / 100;
  }

  videoEditOptions(actionType) {
    this.setVideoEditOptions(this.prevAnnoID, this.prevAnnoType);
    switch (actionType) {
      case "edit-text":
        this.addTextToVideo();
        break;
      case "edit-shapes":
        break;
      case "edit-blur":
        this.addBlurToVideo();
        break;
      case "edit-crop":
        this.cropVideoLength();
        break;
      case "edit-effect":
        //this.addEffectOnVideo();
        break;
      case "edit-music":
        //this.uploadBackgroundMusic();
        break;
      case "edit-link":
        break;
      case "edit-image":
      //this.addImageToVideo();
      case "edit-speed":
        this.addSpeedToVideo();
        break;
      case "edit-voice-over":
        this.addRecordingToVideo();
        break;
      case "add-text-speech":
        this.addTextToSpeechOnVideo();
        break;
      case "edit-video-gif":
        this.editVideoGif();
        break;
    }
  }

  videoPlayerActions(video, actionType) {
    switch (actionType) {
      case "prev":
        video.playbackRate = 0.5;
        break;
      case "backward":
        video.currentTime -= 10;
        break;
      case "play":
        jQuery(".video-editor-play").hide();
        jQuery(".video-editor-pause").show();
        video.play();
        break;
      case "pause":
        jQuery(".video-editor-play").show();
        jQuery(".video-editor-pause").hide();
        video.pause();
        break;
      case "forward":
        video.currentTime += 10;
        break;
      case "next":
        video.playbackRate = 1.5;
        break;
    }
  }

  addTextToSpeechOnVideo() {
    this.displayTextToSpeechContent();
    jQuery(".quix-editor-function-popup-wrapper").removeClass(
      "quix-active-function-popup"
    );
    jQuery(".quix-editor-function-popup-wrapper").toggleClass(
      "quix-active-function-popup"
    );
    jQuery("#quix-dashboard-overlay").css({ display: "block" });
  }

  checkVideLength(videoDuration) {
    var duration = this.sg_HmsToseconds(this.totalVideoLength);
    var progressPercent = (videoDuration / duration) * 100;
    if (duration >= videoDuration) {
      return progressPercent;
    } else {
      // alert('That videos needs to be more than 5 seconds.')
      return false;
    }
  }

  addRecordingToVideo() {
    this.displayVoiceContent();
    jQuery(".quix-editor-function-popup-wrapper").removeClass(
      "quix-active-function-popup"
    );
    jQuery(".quix-editor-function-popup-wrapper").toggleClass(
      "quix-active-function-popup"
    );
    jQuery("#quix-dashboard-overlay").toggle();
  }

  addEffectOnVideo(data, id) {
    // this.intervalSelectorBox("editeffect", data, id);
    jQuery(".quix-video-effect-wrapper").toggleClass(
      "quix-video-effect-active"
    );
  }

  intervalSelectorBox(type, name, data, id) {
    var selHTML = "";
    // selHTML = '<div class="int-select-box" id="int-select-box-'+selectionId+'"><div class="int-select-box-inner"><span>'+type+'</span></div></div>';
    if (id !== undefined) {
      this.selectionId = id;
    } else {
      this.selectionId++;
      this.prevAnnoID = this.selectionId;
      this.prevAnnoType = type;
    }
    if (data !== undefined) {
      data = data.points;
      var marginBottom = 24;
      this.positionPlayBarVideoProgressEdit(data.start, data.end, (res) => {
        var new_width = res.width;
        var new_height = 25;
        var new_offset = { bottom: marginBottom, left: res.left };
        if (type == "gif") {
          var widthGif = this.checkVideLength(5);
          new_width = `${widthGif}%`;
        }
        this.addINTBOX(
          type,
          name,
          this.selectionId,
          new_width,
          new_height,
          new_offset,
          marginBottom
        );
      });
      // jQuery("#video-editor-editing-selection-inner").append(selHTML);
    } else {
      var marginBottom = 24;
      var new_width = 78;
      var new_height = 25;
      var new_offset = { bottom: marginBottom, left: 0 };
      var extraClass = "";
      var insertTime = "";
      var insertType = "";
      if (type == "gif") {
        var widthGif = this.checkVideLength(5);
        new_width = `${widthGif}%`;
      }
      if (type == "insert") {
        let insertDetailsData =
          this.insertFormData[this.insertFormData.length - 1];
        if (
          insertDetailsData.video_position === "start" ||
          insertDetailsData.video_position === "end"
        ) {
          extraClass = `insert-${insertDetailsData.video_position}`;
          insertTime = insertDetailsData.video_duration;
          insertType = insertDetailsData.video_position;
          return this.addINTBOX(
            type,
            name,
            this.selectionId,
            new_width,
            new_height,
            new_offset,
            marginBottom,
            extraClass,
            insertType,
            insertTime
          );
        } else {
          extraClass = `insert-${insertDetailsData.video_position}`;
          // insertTime = insertDetailsData.video_duration;
          insertType = insertDetailsData.video_position;
          return this.addINTBOX(
            type,
            name,
            this.selectionId,
            new_width,
            new_height,
            new_offset,
            marginBottom,
            extraClass,
            insertType,
            insertTime
          );
        }
      }
      this.addINTBOX(
        type,
        name,
        this.selectionId,
        new_width,
        new_height,
        new_offset,
        marginBottom
      );
    }
  }
  async fetchMP3AndConvertToBlob(mp3Url) {
    try {
      // Fetch MP3 data
      const response = await fetch(mp3Url);

      // Convert response to blob
      const blob = await response.blob({ type: "audio/mpeg" });

      // Return the blob
      return blob;
    } catch (error) {
      console.error("Error fetching MP3:", error);
      return null;
    }
  }

  playerAudio = async (elem, file) => {
    if (file) {
      if (file instanceof File) {
        file = file;
      } else {
        let fileName = file?.name;
        let fileUrl = decodeURIComponent(
          this.API_SERVER +
          "/" +
          decodeURIComponent(fileName).split("public/")?.[1]
        );
        let name = fileUrl.split("audios/")[1];
        file = await this.urlToFile(fileUrl, name, "audio/mp3").then(
          (file) => file
        );
      }
      let blob;
      blob = new Blob([file], { type: "audio/mp3" });
      blob = URL.createObjectURL(blob);
      if (this.Mp3audio != "") {
        this.Mp3audio.pause();
      }
      setTimeout(() => {
        this.Mp3audio = new Audio(blob);
        this.Mp3audio.onplay = function () {
          elem.find("#quix-play-audio").css("display", "none");
          elem.find("#quix-pause-audio").css("display", "block");
        };
        this.Mp3audio.onended = function () {
          elem.find("#quix-play-audio").css("display", "block");
          elem.find("#quix-pause-audio").css("display", "none");
        };
        this.Mp3audio.onpause = function () {
          elem.find("#quix-play-audio").css("display", "block");
          elem.find("#quix-pause-audio").css("display", "none");
        };
        this.Mp3audio.play();
      }, 100);
    } else {
      alert("file not found!");
    }
  };

  stopAudio(elem) {
    if (this.Mp3audio) {
      this.Mp3audio.pause();
    }
    elem.find("#quix-play-audio").css("display", "block");
    elem.find("#quix-pause-audio").css("display", "none");
  }

  addPlayerHtml(elem) {
    if (elem) {
      const playerTitle = jQuery("#" + elem).find(".int-select-box-inner");
      var audioHtml = "";
      audioHtml =
        '<span class="quix-audio-player"><img id="quix-play-audio" src="' +
        playIcon +
        '" action-type="play-audio"/><img  id="quix-pause-audio" src="' +
        pauseIcon +
        '" action-type="pause-audio"/></span>';
      playerTitle.append(audioHtml);
      jQuery(".quix-audio-player img").unbind("click");
      jQuery(".quix-audio-player img").on("click", (e) => {
        let actionType = jQuery(e.currentTarget).attr("action-type");
        let index = jQuery(e.currentTarget)
          .parents(".int-select-box")
          .attr("data-bg-index");
        let audioData = this.uploadedBGMusic[Number(index)];
        if (actionType == "play-audio") {
          this.playerAudio(
            jQuery(e.currentTarget).parents(".quix-audio-player"),
            audioData
          );
        } else {
          this.stopAudio(jQuery(e.currentTarget).parents(".quix-audio-player"));
        }
      });
    }
  }

  getDuration = (file, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!file) {
          return reject("No file provided");
        }
        if (!(file instanceof File)) {
          let fileName = file?.name;
          let fileUrl = decodeURIComponent(
            this.API_SERVER +
            "/" +
            decodeURIComponent(fileName).split("public/")?.[1]
          );
          if (type === "audio") {
            let name = fileUrl.split("audios/")[1];
            file = await this.urlToFile(fileUrl, name, "audio/mp3");
          } else if (type === "video") {
            let name = fileUrl.split("videos/")[1];
            file = await this.urlToFile(fileUrl, name, "video/mp4");
          }
        }
        if (file?.type?.startsWith("audio")) {
          const durationVideo = this.sg_HmsToseconds(this.totalVideoLength);
          const reader = new FileReader();
          reader.onloadend = function () {
            const arrayBuffer = reader.result;
            const audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            audioContext.decodeAudioData(
              arrayBuffer,
              function (buffer) {
                const durationInSeconds = buffer.duration;
                const width = (durationInSeconds / durationVideo) * 100;
                resolve(width);
              },
              function (error) {
                reject("Error decoding audio: " + error);
              }
            );
          };
          reader.readAsArrayBuffer(file);
        } else if (file?.type?.startsWith("video")) {
          const durationVideo = this.sg_HmsToseconds(this.totalVideoLength);
          const videoElement = document.createElement("video");
          videoElement.onloadedmetadata = () => {
            const width = (videoElement.duration / durationVideo) * 100;
            resolve(width);
          };
          videoElement.onerror = () => {
            reject("Failed to load video metadata");
          };
          const fileUrl = URL.createObjectURL(file);
          videoElement.src = fileUrl;
        }
      } catch (error) {
        reject("Error processing file: " + error);
      }
    });
  };

  addINTBOX(
    type,
    name,
    id,
    new_width,
    new_height,
    new_offset,
    marginBottom,
    extraClass,
    insertType,
    insertTime
  ) {
    if (id !== undefined) {
      this.selectionId = id;
    }
    var elemId = "int-select-box-" + this.selectionId;
    var bgMelemId = "";
    if (type == "bgMusic") {
      this.getDuration(
        this.uploadedBGMusic[this.uploadedBGMusic.length - 1],
        "audio"
      ).then((duration) => {
        let setMediaLength = setInterval(() => {
          if (jQuery("#" + elemId)) {
            jQuery("#" + elemId).attr("media-length", duration);
            jQuery("#" + elemId).css("max-width", `${duration}%`);
            clearInterval(setMediaLength);
          }
        }, 100);
      });
      bgMelemId = 'data-bg-index="' + (this.uploadedBGMusic.length - 1) + '"';
    }
    if (type == "image") {
      bgMelemId = 'data-bg-index="' + (this.uploadedBGImages.length - 1) + '"';
    }
    if (type == "upload-video") {
      bgMelemId = 'data-bg-index="' + (this.uploadedBGVideos.length - 1) + '"';
      this.getDuration(
        this.uploadedBGVideos[this.uploadedBGVideos.length - 1],
        "video"
      ).then((duration) => {
        let setMediaLength = setInterval(() => {
          if (jQuery("#" + elemId)) {
            jQuery("#" + elemId).attr("media-length", duration);
            jQuery("#" + elemId).css("max-width", `${duration}%`);
            clearInterval(setMediaLength);
          }
        }, 100);
      });
    }
    // if (type == "voiceover") {
    //   bgMelemId = 'data-bg-index="' + (this.uploadedBGMusic.length - 1) + '"';
    // }
    if (type == "insert") {
      let insertIndex = this.uploadedInsertItems.length - 1;
      // jQuery("#" + elemId).attr("insert-index",this.uploadedInsertItems.length - 1);
      let setInsertIndex = setInterval(() => {
        jQuery("#" + elemId).attr("insert-index",insertIndex);
        clearInterval(setInsertIndex);
      }, 100);
      // new_width = "max-content";
      let typeInsertMedia = this.uploadedInsertItems[insertIndex].type;
      if (typeInsertMedia.includes("video")) {
        this.getDuration( this.uploadedInsertItems[insertIndex], "video").then((duration) => {
          let setMediaLength = setInterval(() => {
            jQuery("#" + elemId).attr("media-length", duration);
            jQuery("#" + elemId).css({ "max-width": `${duration}%` });
            clearInterval(setMediaLength);
          }, 100);
        });
      }
    }
    // if (type == "texttospeech") {
    //   bgMelemId = 'data-bg-index="' + (this.uploadedBGMusic.length - 1) + '"';
    // }
    // marginBottom = marginBottom*(id-1);
    if (id == marginBottom) {
      marginBottom = 0;
    }
    var anntitle = name;
    if (type == "crop") {
      anntitle = "trim";
    }
    if (type == "gif") {
      anntitle = "gif";
    }
    jQuery(
      '<div title="' +
      anntitle +
      '" class="int-select-box ' +
      type +
      " " +
      extraClass +
      " int-select-box-" +
      this.selectionId +
      '" ' +
      bgMelemId +
      ' data-index="' +
      this.selectionId +
      '"' +
      ' data-insert-type="' +
      insertType +
      '"' +
      ' data-insert-length="' +
      insertTime +
      '" ' +
      'id="' +
      elemId +
      '"><div class="int-select-box-inner"><span class="box-timer-left"></span><span class="box-title">' +
      // anntitle
      `${insertTime ? `<span class='insert-time'>${insertTime} sec</span>` : ""
      }<img class="int-select-box-icon" src="${name}" />` +
      '</span><span class="box-timer-right"></span><span class="box-timer-close"><img src="' +
      closeIcon +
      '"/></span></div></div>'
    )
      .width(new_width)
      .height(new_height)
      .draggable({
        cancel: "text",
        containment: "parent",
        axis: "x",
        scroll: false,
        start: function (e) { },
        stop: (e) => {
          var blockW = jQuery("#video-annotation-blocks-inner").width();
          var elemL = e.target.offsetLeft;
          var elemW = e.target.offsetWidth;
          var diff = elemW + elemL - blockW;
          if (diff > 0) {
            jQuery(e.currentTarget).css({
              left: parseInt(elemL - diff) + "px",
            });
          }
          this.intSelectBoxEvents(elemId);
        },
      })
      .resizable({
        cancel: "text",
        aspectRatio: false,
        containment: "parent",
        handles: "e,w",
        scroll: false,
        resize: (e) => {
          this.intSelectBoxEvents(elemId);
        },
        start: function (e) { },
        stop: (e) => {
          var blockW = jQuery("#video-annotation-blocks-inner").width();
          var elemL = e.target.offsetLeft;
          var elemW = e.target.offsetWidth;
          var diff = elemW + elemL - blockW;
          if (diff > 0) {
            jQuery(e.currentTarget).css({
              left: parseInt(elemL - diff) + "px",
            });
          }
          this.intSelectBoxEvents(elemId);
        },
      })
      .css({
        position: "relative",
        border: "2px solid #525FB0",
      })
      .offset(new_offset)
      .prependTo("#video-annotation-blocks-inner");
    setTimeout(() => {
      this.intSelectBoxEvents(elemId);
      if (jQuery("#" + elemId).hasClass("bgMusic")) {
        this.addPlayerHtml(elemId);
      }
    }, 0);

    if (jQuery(".int-select-box").hasClass("gif")) {
      jQuery(".int-select-box.gif").resizable({
        disabled: true,
      });
    }
    if (jQuery(".int-select-box")) {
      jQuery(".int-select-box.insert").on("resize", async (e) => {
        var index = jQuery(e.currentTarget).attr("data-index");
        var width = jQuery(e.currentTarget).attr("media-length");
        let itemType = this.uploadedInsertItems[index - 1]?.type;
        let videPostion = this.insertFormData[index - 1]?.video_position;
        if (itemType?.includes("video") && videPostion === "middle") {
          jQuery(e.currentTarget).css({ "max-width": `${width}%` });
        }
      });
      jQuery(".int-select-box.bgMusic").on("resize", async (e) => {
        var width = jQuery(e.currentTarget).attr("media-length");
        jQuery(e.currentTarget).css({ "max-width": `${width}%` });
      });
      jQuery(".int-select-box.upload-video").on("resize", async (e) => {
        var width = jQuery(e.currentTarget).attr("media-length");
        jQuery(e.currentTarget).css({ "max-width": `${width}%` });
      });
    }
    if (jQuery(".int-select-box").hasClass("insert")) {
      jQuery(".int-select-box.insert-start").find(".box-timer-left").remove();
      jQuery(".int-select-box.insert-start").find(".box-timer-right").remove();
      jQuery(".int-select-box.insert-end").find(".box-timer-left").remove();
      jQuery(".int-select-box.insert-end").find(".box-timer-right").remove();
      jQuery(".int-select-box.insert-start").resizable({
        disabled: true,
      });
      jQuery(".int-select-box.insert-start").draggable({
        disabled: true,
      });
      if (jQuery(".int-select-box.insert-end")) {
        let insertID = jQuery(".int-select-box.insert-end").attr("id");
        jQuery(`.int-select-box.insert-end#${insertID}`).css("left", `unset`);
        jQuery(`.int-select-box.insert-end#${insertID}`).css("float", "right");
        jQuery(".int-select-box.insert-end").resizable({
          disabled: true,
        });
        jQuery(".int-select-box.insert-end").draggable({
          disabled: true,
        });
      }
    }

    jQuery(".int-select-box").on("mouseover", (e) => {
      jQuery(e.currentTarget).find(".box-timer-left").show();
      jQuery(e.currentTarget).find(".box-timer-right").show();
    });

    //jQuery(".int-select-box .ui-resizable-e").unbind("mouseout");
    jQuery(".int-select-box").on("mouseout", (e) => {
      jQuery(e.currentTarget).find(".box-timer-left").hide();
      jQuery(e.currentTarget).find(".box-timer-right").hide();
    });

    jQuery(document).on("click", ".editor-video-box", (e) => {
      var index = jQuery(e.currentTarget).attr("data-index");
      this.editSettingsLoad(jQuery(".int-select-box-" + index));
      jQuery(".int-select-box").removeClass("active");
      jQuery(".editor-video-box").removeClass("active");
      jQuery(e.currentTarget).addClass("active");
      jQuery(".int-select-box-" + index).addClass("active");
      jQuery(e.currentTarget).find("#textarea").focus();
    });

    jQuery(".int-select-box").unbind("click");
    jQuery(".int-select-box").on("click", (e) => {
      this.editSettingsLoad(e.currentTarget);
      var index = jQuery(e.currentTarget).attr("data-index");
      jQuery(".int-select-box").removeClass("active");
      jQuery(".editor-video-box").removeClass("active");
      jQuery(e.currentTarget).addClass("active");
      jQuery(".editor-video-box-" + index).addClass("active");
      // $(".editor-video-box-"+index).find("textarea").focus();
    });

    jQuery(".int-select-box.insert").unbind("click");
    jQuery(".int-select-box.insert").on("click", (e) => {
      jQuery(".int-select-box").removeClass("active");
      jQuery(".editor-video-box").removeClass("active");
      // Edit insert form
      jQuery(e.currentTarget).addClass("active");
      if (jQuery(e.currentTarget).hasClass("insert")) {
        var keyPressed = false;
        jQuery(document).on("drag", function () {
          keyPressed = true;
        });
        jQuery(e.currentTarget).on("click", function () {
          if (!keyPressed) {
            let clickedElement = jQuery(e.currentTarget);
            let insertTyp = jQuery(clickedElement).attr('data-insert-type');
            if(insertTyp !== 'start' && insertTyp !== 'end'){
              return;
            }
            jQuery("#insert-form .quix-inser-save-btn").addClass("edit-insert");
            jQuery("#quix-insert-details-popup").css("display", "block");
            jQuery("#quix-dashboard-overlay").css({ display: "block" });
            jQuery("#insert-error").css({ display: "none" });
            let insertInd = jQuery(clickedElement).attr('insert-index');
            // let insertTyp = jQuery(clickedElement).attr('data-insert-type');
            let insertLength = jQuery(clickedElement).attr('data-insert-length');
            if(insertTyp === 'start' || insertTyp === 'end'){
              jQuery('.quix-insert-video-size').css('display','none');
              jQuery("input#video-size").val(insertLength);
              jQuery("input#video-size").prop('disabled',false);
              jQuery("input#video-size").prop('disabled',false);
            }else{
              // jQuery('.quix-insert-form-fields input').val('');
              return;
            }
          }
        });
      }
    });

    // close editing option when close int select box
    const closeEditingOption = () => {
      setTimeout(() => {
        jQuery("#video-editor-editing-options").css({ display: "none" });
        const selectInt = jQuery(".int-select-box");
        const selectBoxes = jQuery("editor-video-box");
        if (selectInt.length > 0) {
          selectInt.last().click();
        }
        if (selectBoxes.length > 0) {
          selectBoxes.last().click();
        }
      }, 100);
    };

    jQuery(".box-timer-close").unbind("click");
    jQuery(".box-timer-close").on("click", (e) => {
      var index = jQuery(e.currentTarget)
        .parents(".int-select-box")
        .attr("data-index");
      if (jQuery(e.currentTarget).parents(".int-select-box").hasClass("text")) {
        this.closeCompLayer(index, ".editor-text-box");
        closeEditingOption();
      } else if (
        jQuery(e.currentTarget).parents(".int-select-box").hasClass("blur")
      ) {
        closeEditingOption();
        this.closeCompLayer(index, ".editor-blur-box");
      } else if (
        jQuery(e.currentTarget).parents(".int-select-box").hasClass("image")
      ) {
        closeEditingOption();
        this.closeCompLayer(index, ".editor-video-image");
        var bgindex = jQuery(e.currentTarget)
          .parents(".int-select-box")
          .attr("data-bg-index");
        delete this.uploadedBGImages[bgindex];
      } else if (
        jQuery(e.currentTarget)
          .parents(".int-select-box")
          .hasClass("upload-video")
      ) {
        closeEditingOption();
        this.closeCompLayer(index, ".editor-video-video");
        var bgindex = jQuery(e.currentTarget)
          .parents(".int-select-box")
          .attr("data-bg-index");
        delete this.uploadedBGVideos[bgindex];
      } else if (
        jQuery(e.currentTarget).parents(".int-select-box").hasClass("bgMusic")
      ) {
        closeEditingOption();
        var bgindex = jQuery(e.currentTarget)
          .parents(".int-select-box")
          .attr("data-bg-index");
        delete this.uploadedBGMusic[bgindex];
      } else if (
        jQuery(e.currentTarget).parents(".int-select-box").hasClass("insert")
      ) {
        closeEditingOption();
        var bgindex = jQuery(e.currentTarget)
          .parents(".int-select-box")
          .attr("insert-index")
        delete this.uploadedInsertItems[bgindex];
      }
      jQuery(e.currentTarget).parents(".int-select-box").remove();
    });
  }

  closeCompLayer(index, cls) {
    var elem = jQuery(cls);
    for (let i = 0; i < elem.length; i++) {
      var compIndex = jQuery(elem[i]).attr("data-index");
      if (compIndex == index) {
        jQuery(elem[i]).remove();
        return;
      }
    }
  }

  editVideoGif() {
    this.intervalSelectorBox("gif", gifIcon);
  }

  cropVideoLength() {
    this.intervalSelectorBox("crop", cropIcon);
  }

  addInsertToVideoComp(filedata, data, id) {
    if (filedata) {
      if (jQuery("#video-editor-content-inner canvas").length <= 0) {
        var canvasHTML = '<canvas id="video-layer"></canvas>';
        jQuery("#video-editor-content-inner").append(canvasHTML);
      }
      this.intervalSelectorBox("insert", insertIcon, data, id);
      // if(data !== undefined)
      // {
      //     data = data.data;
      //     addImageToComp(data.val,data,id);
      // }
      // else
      // {
      //     let reader = new FileReader();
      //     reader.onload = function(event)
      //     {
      //         var image = new Image();
      //         image.src = event.target.result;
      //         image.onload = function()
      //         {
      //             addImageToComp(event.target.result,undefined,undefined,image.width,image.height);
      //         }
      //     }
      //     reader.readAsDataURL(filedata);
      // }
    }
  }
  addImageToVideoComp(filedata, data, id) {
    if (filedata) {
      if (jQuery("#video-editor-content-inner canvas").length <= 0) {
        var canvasHTML = '<canvas id="video-layer"></canvas>';
        jQuery("#video-editor-content-inner").append(canvasHTML);
      }
      this.intervalSelectorBox("image", photoUploadIcon, data, id);
      if (data !== undefined) {
        data = data.data;
        let imgSrc = checkServerURL(API_URL, data.val);
        this.addImageToComp(imgSrc, data, id);
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          var image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            this.addImageToComp(
              event.target.result,
              undefined,
              undefined,
              image.width,
              image.height
            );
          };
        };
        reader.readAsDataURL(filedata);
      }
    }
  }

  addVideoToVideoComp(filedata, data, id) {
    if (filedata) {
      if (jQuery("#video-editor-content-inner canvas").length <= 0) {
        var canvasHTML = '<canvas id="video-layer"></canvas>';
        jQuery("#video-editor-content-inner").append(canvasHTML);
      }
      this.intervalSelectorBox("upload-video", videoUploadIcon, data, id);
      if (data !== undefined) {
        data = data.data;
        let urlName = data.val;
        let vdoSrc = decodeURIComponent(
          this.API_SERVER +
          "/" +
          decodeURIComponent(urlName).split("public/")?.[1]
        );
        this.addVideoToComp(vdoSrc, data, id);
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          var video = document.createElement("video");
          video.src = event.target.result;
          video.onloadedmetadata = () => {
            this.addVideoToComp(
              event.target.result,
              undefined,
              undefined,
              video.videoWidth,
              video.videoHeight
            );
          };
        };
        reader.readAsDataURL(filedata);
      }
    }
  }

  addImageToComp(src, data, id, w, h) {
    if (id !== undefined) {
      this.selectionId = id;
    }
    if (data !== undefined) {
      data = data;
      var x = parseInt(data.x * this.videoDimesionsRatioW);
      var y = parseInt(data.y * this.videoDimesionsRatioW);
      var w = parseInt(data.w * this.videoDimesionsRatioW);
      var h = parseInt(data.h * this.videoDimesionsRatioW);
      var new_offset = { top: y, left: x };
      var new_width = w;
      var new_height = h;
    } else {
      var imageAR = w / h;
      var new_offset = { top: 100, left: 100 };
      if (w > 300) {
        w = 300;
      }
      var new_width = w;
      var new_height = w / imageAR;
      this.editingOptionsImage(this.selectionId);
    }
    var elemId = "int-image-upload-" + this.selectionId;
    //jQuery(".editor-outer-image-overlay").remove();
    if (src.indexOf("public") > -1) {
      src = src.replace("public", "");
      src = decodeURIComponent(src);
    }
    jQuery(
      '<div class="editor-video-image editor-video-box editor-video-box-' +
      this.selectionId +
      '" data-index="' +
      this.selectionId +
      '" id="' +
      elemId +
      '"><img src="' +
      src +
      '"/></div>'
    )
      .width(new_width)
      .height(new_height)
      .draggable({
        cancel: "text",
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .resizable({
        cancel: "text",
        aspectRatio: true,
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .css({
        position: "absolute",
        border: "2px solid #525FB0",
      })
      .offset(new_offset)
      .appendTo("#video-editor-content-inner");
    //jQuery("#upload-image").val("");
  }

  addVideoToComp(src, data, id, w, h) {
    if (id !== undefined) {
      this.selectionId = id;
    }
    if (data !== undefined) {
      data = data;
      var x = parseInt(data.x * this.videoDimesionsRatioW);
      var y = parseInt(data.y * this.videoDimesionsRatioW);
      var w = parseInt(data.w * this.videoDimesionsRatioW);
      var h = parseInt(data.h * this.videoDimesionsRatioW);
      var new_offset = { top: y, left: x };
      var new_width = w;
      var new_height = h;
    } else {
      var imageAR = w / h;
      var new_offset = { top: 100, left: 100 };
      if (w > 300) {
        w = 300;
      }
      var new_width = w;
      var new_height = w / imageAR;
      // this.editingOptionsImage(this.selectionId);
    }
    var elemId = "int-video-upload-" + this.selectionId;
    //jQuery(".editor-outer-image-overlay").remove();
    if (src.indexOf("public") > -1) {
      src = src.replace("public", "");
      src = decodeURIComponent(src);
    }
    jQuery(
      '<div class="editor-video-video editor-video-box editor-video-box-' +
      this.selectionId +
      '" data-index="' +
      this.selectionId +
      '" id="' +
      elemId +
      '"><video controls><source src="' +
      src +
      '"/></video></div>'
    )
      .width(new_width)
      .height(new_height)
      .draggable({
        cancel: "text",
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .resizable({
        cancel: "text",
        aspectRatio: true,
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .css({
        position: "absolute",
        border: "2px solid #525FB0",
      })
      .offset(new_offset)
      .appendTo("#video-editor-content-inner");
    //jQuery("#upload-image").val("");
  }

  addTextToVideo(data, id) {
    if (jQuery("#video-editor-content-inner canvas").length <= 0) {
      var canvasHTML = '<canvas id="video-layer"></canvas>';
      jQuery("#video-editor-content-inner").append(canvasHTML);
    }
    this.intervalSelectorBox("text", textIcon, data, id);
    this.addTextToVideoComp(data, id);
  }

  addTextToVideoComp(data, id) {
    let _this = this;
    var text = "";
    this.textIndex++;
    if (id !== undefined) {
      this.selectionId = id;
    }
    if (data !== undefined) {
      data = data.data;
      var x = parseInt(data.x * this.videoDimesionsRatioW);
      var y = parseInt(data.y * this.videoDimesionsRatioW);
      var w = parseInt(data.w * this.videoDimesionsRatioW);
      var h = parseInt(data.h * this.videoDimesionsRatioW);
      var new_offset = { top: y, left: x };
      var new_width = w;
      var new_height = h;
      var elemId = "int-text-box-" + this.selectionId;
      // text = data.val.length ? data.val.join('\n') : data.val;
      text = Array.isArray(data.val) && data.val.length > 0 ? data.val.join('\n') : data.val;
      const textObject = new Object({
        dataIndex: Number(this.selectionId),
        id: elemId,
        text: Array(text),
      });
    } else {
      var new_offset = { top: 100, left: 100 };
      var new_width = 200;
      var new_height = 100;
      var elemId = "int-text-box-" + this.selectionId;
      text = "";
      this.editingOptionsText(this.selectionId);
    }
    jQuery(".editor-outer-image-overlay").remove();
    //  **************************** Text Area Start ****************************
    let editorTextEl = jQuery(
      '<div class="editor-text-box editor-video-box editor-video-box-' +
      this.selectionId +
      '" data-index="' +
      this.selectionId +
      '"' +
      '" text-index="' +
      this.textIndex +
      '" id="' +
      elemId +
      '"> ' +
      '<form id="quix-textare-form-' +
      this.selectionId +
      '" class="text-editor-form" name="main" method="get">' +
      '<textarea id="textarea" name="bar" cols=5 wrap="hard" placeholder="Write text here">' +
      text +
      "</textarea>" +
      '<input type="submit">' +
      "</form>" +
      "</div>"
    )
      .width(new_width)
      .height(new_height)
      .css({
        position: "absolute",
        border: "2px solid #525FB0",
      })
      .offset(new_offset)
      .appendTo("#video-editor-content-inner");

    let intervalActive = setInterval(() => {
      if (jQuery(editorTextEl).length > 0) {
        if (jQuery(editorTextEl).attr("id").includes("int-text-box-1")) {
          jQuery(editorTextEl).addClass("active").click();
          jQuery(".int-select-box-1").addClass("active");
        }
        clearInterval(intervalActive);
      }
    }, 100);

    // resizable
    editorTextEl.resizable({
      cancel: "text",
      aspectRatio: false,
      containment: "parent",
      start: function () { },
      stop: function () { },
    });
    // draggable
    editorTextEl.draggable({
      cancel: "text",
      containment: "parent",
      start: function () { },
      stop: function () { },
    });

    editorTextEl.on("click", "textarea", () => {
      editorTextEl.draggable({ disabled: true });
      setTimeout(() => {
        editorTextEl.draggable({ disabled: false });
      }, 500);
    });

    editorTextEl.on("mousedown", "textarea", () => {
      editorTextEl.find("textarea").css({ cursor: "move" });
    });

    editorTextEl.on("mouseup", "textarea", () => {
      editorTextEl.find("textarea").css({ cursor: "text" });
    });

    this.adjustTextAreaHeightAndWidth(jQuery(editorTextEl).find('textarea#textarea'));
    editorTextEl.find("textarea").on("keyup", async (event) => {
      let value = editorTextEl.find("textarea").val();
      if (event.originalEvent && event.originalEvent.inputType !== "insertFromPaste") {
        editorTextEl.find("textarea").text(value);
      }
    });
    this.applyVideoEditFront(this.selectionId, "text");
  }

  processForm = (event, selectedInd, elemId) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    const text = formData.get("bar") || "";
    const arrTxt = text.split(/\r?\n|\r|\n/g);
    const textObject = new Object({
      dataIndex: Number(selectedInd),
      id: elemId,
      text: arrTxt,
    });
    this.videoEditTextStrings.push(textObject);
  };

  getCaretPosition = (event) => {
    // console.log(event.clientX, event.clientY,"**&&**");
    var range = document.caretRangeFromPoint(event.clientX, event.clientY);
    var textNode = range.startContainer;
    var offset = range.startOffset;
    var charIndex = 0;
    if (textNode.nodeType == 3) {
      var text = textNode.nodeValue;
      for (var i = 0; i < text.length; i++) {
        if (i == offset) {
          break;
        }
        if (text[i] !== "") {
          charIndex++;
        }
      }
    }
    return charIndex;
  };

  // on Input event adjust dimesions of text box as user types in text into textarea
  adjustTextAreaHeightAndWidth = (elem) => {
    elem.on("input", (e) => {
      this.adjustTextAreaAutomatically();
    });
    elem.on("paste", (event) => {
      event.preventDefault();
      const text = (event.originalEvent || event).clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
      this.adjustTextAreaAutomatically();
    });
  };

  adjustTextAreaAutomatically = () => {
    if (jQuery(".editor-text-box.active")) {
      let screenWidth = jQuery("#video-editor-content-inner").width();
      screenWidth = parseInt(screenWidth) - 100;
      let screenHeight = jQuery("#video-editor-content-inner").height();
      screenHeight = parseInt(screenHeight) - 100;
      let fontSize = parseInt(jQuery("#choose-font-size").val());
      let textContent = document.querySelector(".editor-text-box.active textarea#textarea");
      if (!textContent) return;
      textContent = textContent.value;
      const closestForeignObject = jQuery(".editor-text-box.editor-video-box.active");
      if (textContent.length > 1) {
        let textScrollH = jQuery(".editor-text-box.active textarea#textarea").prop("scrollHeight");
        let w = parseInt(jQuery(".editor-text-box.active textarea#textarea").width());
        let h = parseInt(jQuery(".editor-text-box.active textarea#textarea").height());
        var diffBet = textScrollH - h;
        if (textScrollH !== undefined && textScrollH > 0 && diffBet > 2) {
          var incW = parseInt(w) + parseInt(textScrollH / 3);
          if (incW > screenWidth) {
            incW = screenWidth;
          }
          closestForeignObject.width(incW + "px");
          closestForeignObject.attr("width", incW + "px");
          var tScrollH = jQuery(".editor-text-box.active #textarea").prop("scrollHeight");
          if (tScrollH !== undefined && tScrollH > 0) {
            var incH = parseInt(tScrollH + parseInt(fontSize));
            if (incH > screenHeight) {
              incH = screenHeight;
            }
            if (closestForeignObject.length > 0) {
              closestForeignObject.height(incH + "px");
              closestForeignObject.attr("height", incH + "px");
            }
          }
        }
      } else {
        const minHeight = "96px";
        const minWidth = "196px";
        closestForeignObject.width(minWidth);
        closestForeignObject.attr("width", minWidth);
        closestForeignObject.height(minHeight);
        closestForeignObject.attr("height", minHeight);
      }
    }
  };

  addBlurToVideo(data, id) {
    if (jQuery("#video-editor-content-inner canvas").length <= 0) {
      var canvasHTML = '<canvas id="video-layer"></canvas>';
      jQuery("#video-editor-content-inner").append(canvasHTML);
    }
    this.intervalSelectorBox("blur", blurIcon, data, id);
    this.addBlurToVideoComp(data, id);
  }

  addSpeedToVideo(data, id) {
    this.editingOptionsSpeed(id, data);
  }

  addBlurToVideoComp(data, id) {
    if (id !== undefined) {
      this.selectionId = id;
    }
    if (data !== undefined) {
      data = data.data;
      var x = parseInt(data.x * this.videoDimesionsRatioW);
      var y = parseInt(data.y * this.videoDimesionsRatioW);
      var w = parseInt(data.w * this.videoDimesionsRatioW);
      var h = parseInt(data.h * this.videoDimesionsRatioW);
      var new_offset = { top: y, left: x };
      var new_width = w;
      var new_height = h;
      var elemId = "int-blur-box-" + this.selectionId;
    } else {
      var new_offset = { top: 100, left: 100 };
      var new_width = 200;
      var new_height = 200;
      var elemId = "int-blur-box-" + this.selectionId;
      this.editingOptionsBlur(this.selectionId);
    }
    jQuery(
      '<div class="editor-blur-box editor-video-box editor-video-box-' +
      this.selectionId +
      '" data-index="' +
      this.selectionId +
      '" id="' +
      elemId +
      '"><div class="editor-blur-box-inner"></div></div>'
    )
      .width(new_width)
      .height(new_height)
      .draggable({
        cancel: "text",
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .resizable({
        cancel: "text",
        aspectRatio: false,
        containment: "parent",
        start: function () { },
        stop: function () { },
      })
      .css({
        position: "absolute",
        border: "2px solid #525FB0",
      })
      .offset(new_offset)
      .appendTo("#video-editor-content-inner");
    this.applyVideoEditFront(this.selectionId, "blur");
  }

  intSelectBoxEvents(elemId) {
    var elemSel = jQuery("#" + elemId);
    var ponitsOffset = elemSel?.offset();
    var leftPos = ponitsOffset?.left;
    var outerOffset = jQuery("#video-editor-editing-selection").offset();
    var outerOffsetLeft = parseInt(outerOffset.left);
    leftPos = leftPos - outerOffsetLeft;
    var widthCord = elemSel.width();
    this.calculaterSelectionAreaPoints(elemSel, leftPos, widthCord);
  }

  calculaterSelectionAreaPoints(obj, leftPos, widthCord) {
    var startPoint = this.sg_secondsToHms(this.calculateVideoProgress(leftPos));
    var endPoint = this.sg_secondsToHms(
      this.calculateVideoProgress(leftPos + widthCord)
    );
    jQuery(obj).find(".box-timer-left").text(startPoint);
    jQuery(obj).find(".box-timer-right").text(endPoint);
  }

  editVideoAnnotations(jsonData) {
    if (this.isAnnotationsEdited) return;
    jsonData = jsonData.replace(/\n/g, "%%NEWLINE%%");
    jsonData = JSON.parse(jsonData);
    var blur_points = jsonData.blur_points;
    var text_points = jsonData.text_points;
    var image_points = jsonData.image_points;
    var video_points = jsonData.video_points;
    var bgMusic_points = jsonData.bgMusic_points;
    var crop_points = jsonData.crop_points;
    var insert_points = jsonData.insert_points;
    // var voiceOver_points = jsonData.bgMusic_points;
    // var text_to_speech_points =  jsonData.bgMusic_points;
    var id = 1;
    var settings = {};
    var blurData;
    var textData;
    var imageData;
    var videoData;
    var bgMusicData;
    var voiceOverData;
    var textToSpeechData;
    var cropData;
    var gifData;
    if (blur_points.length > 0) {
      for (let i = 0; i < blur_points.length; i++) {
        blurData = blur_points[i];
        settings = {
          intensity: blurData.data.intensity,
        };
        this.videoEditOptionItems[id - 1] = settings;
        this.addBlurToVideo(blurData, id);
        id++;
      }
    }
    if (text_points.length > 0) {
      for (let i = 0; i < text_points.length; i++) {
        textData = text_points[i];
        settings = {
          "font-size-real": textData.data.fontSize,
          // "font-size": parseInt(
          //   parseInt(textData.data.fontSize) * this.videoDimesionsRatioW
          // ),
          "font-size": parseInt(textData.data.fontSize),
          "font-bold": textData.data.fontWeight,
          "font-italic": textData.data.fontStyle,
          "font-color": textData.data.fontColor,
          "font-align": textData.data.fontAlign,
        };
        this.videoEditOptionItems[id - 1] = settings;
        this.addTextToVideo(textData, id);
        id++;
      }
    }
    if (image_points.length > 0) {
      for (let i = 0; i < image_points.length; i++) {
        imageData = image_points[i];
        //uploadedBGImages.push({'name' : imageData.data.val});
        settings = {
          "img-shadow": imageData.data.shadow,
          "img-opacity": imageData.data.opacity,
        };
        this.videoEditOptionItems[id - 1] = settings;
        this.addImageToVideoComp(imageData.data.val, imageData, id);
        id++;
      }
    }
    if (video_points.length > 0) {
      for (let i = 0; i < video_points.length; i++) {
        videoData = video_points[i];
        settings = {
          "img-shadow": videoData.data.shadow,
          "img-opacity": videoData.data.opacity,
        };
        this.videoEditOptionItems[id - 1] = settings;
        let file = new Object({
          name: videoData.data.val,
        });
        this.uploadedBGVideos.push(file);
        this.addVideoToVideoComp(videoData.data.val, videoData, id);
        id++;
      }
    }
    if (bgMusic_points.length > 0) {
      for (let i = 0; i < bgMusic_points.length; i++) {
        bgMusicData = bgMusic_points[i];
        this.uploadedBGMusic.push({ name: bgMusicData.data.val });
        this.intervalSelectorBox("bgMusic", bgMusicIcon, bgMusicData, id);
        id++;
      }
    }
    if (crop_points.length > 0) {
      let cropsArray = calculateIntervals(crop_points);
      for (let i = 0; i < cropsArray.length; i++) {
        cropData = cropsArray[i];
        let start = sg_interval_HmsToseconds(cropData?.start);
        let end = sg_interval_HmsToseconds(cropData?.end);
        let data = { points: { start: start, end: end } }
        this.intervalSelectorBox("crop", cropIcon, data, id);
        id++;
      }
    }
    if (insert_points?.length > 0) {
      for (let i = 0; i < insert_points.length; i++) {
        let insertData = insert_points[i];
        let fileUrl = `${API_URL}/` + decodeURIComponent(insertData?.data?.val).split('public/')?.[1];
        let filedata;
        let data;
        let formData;
        if (fileUrl?.includes('/videos/images')) {
          let name = fileUrl.split("images/")[1];
          filedata = this.uploadedInsertItems.push({ name: insertData.data.val, type: 'image' })
        } else {
          let name = fileUrl.split("videos/")[1];
          filedata = this.uploadedInsertItems.push({ name: insertData.data.val, type: 'video' })
        }
        if (insertData?.points?.type === 'start') {
          formData = { video_duration: insertData?.points?.length, video_position: 'start', val:insertData.data.val };
        }
        else if (insertData?.points?.type === 'end') {
          formData = { video_duration: insertData?.points?.length, video_position: 'end', val:insertData.data.val };
        } else {
          formData = { video_duration: '', video_position: 'middle', val:insertData.data.val };
          data = { points: { start: insertData?.points?.start, end: insertData?.points?.end } };
        }
        // video_position
        this.insertMedia(formData, filedata)
        this.addInsertToVideoComp(filedata, data, id);
        id++;
      }
    }
    // if (voiceOver_points.length > 0) {
    //   for (let i = 0; i < voiceOver_points.length; i++) {
    //     voiceOverData = voiceOver_points[i];
    //     this.uploadedBGMusic.push({ name: voiceOverData.data.val });
    //     this.intervalSelectorBox("bgMusic","voiceover", voiceOverData, id);
    //     id++;
    //   }
    // }
    // if (text_to_speech_points.length > 0) {
    //   for (let i = 0; i < text_to_speech_points.length; i++) {
    //     textToSpeechData = text_to_speech_points[i];
    //     this.uploadedBGMusic.push({ name: textToSpeechData.data.val });
    //     this.intervalSelectorBox("bgMusic","texttospeech", textToSpeechData, id);
    //     id++;
    //   }
    // }
  }

  editingOptionsText(id, data) {
    var html = "";
    jQuery(".video-editor-editing-options-col.text").html(html);
    jQuery(".video-editor-editing-options-col.blur").html(html);
    jQuery(".video-editor-editing-options-col.image").html(html);
    html += '<span class="fields-row"><label>Text Options: </label></span>';
    html +=
      '<span class="fields-row font-size"><label for="choose-font-size">Font Size</label><input class="form-range form-control border-0" id="choose-font-size" value="14" type="range" min="12" max="230"/></span>';
    html +=
      '<span class="fields-row font-bold"><img title="Text Weight" src=' +
      boldIcon +
      ' /><input title="Text Weight" type="checkbox" name="choose-bold"></span>';
    html +=
      '<span class="fields-row font-italic"><img src="' +
      italicIcon +
      '" title="Text Style" /><input type="checkbox" title="Text Style" name="choose-italic"></span>';
    html +=
      '<div class="fields-row font-align"><span class="fields-col"><img src="' +
      alignLeftIcon +
      '" title="Text Alignment Left" /><input type="radio" title="Text Alignment Left" value="left" name="choose-align" id="choose-left"></span><span class="fields-col"><img src="' +
      alignCenterIcon +
      '" title="Text Alignment Center" /><input type="radio" title="Text Alignment Center" value="center" name="choose-align" id="choose-center"></span><span class="fields-col"><img src="' +
      alignRightIcon +
      '" title="Text Alignment Right" /><input type="radio" title="Text Alignment Right" value="right" name="choose-align" id="choose-right"></span></div>';
    html +=
      '<div class="fields-row font-color"><span style="background-color:#ff0000;"><input type="radio" value="#ff0000" name="choose-color"></span><span style="background-color:#ff9900;"><input type="radio" value="#ff9900" name="choose-color"></span><span style="background-color:#0072e6;"><input type="radio" value="#0072e6" name="choose-color"></span><span style="background-color:#12dc00;"><input type="radio" value="#12dc00" name="choose-color"></span><span style="background-color:#e7e7e7;"><input type="radio" value="#e7e7e7" name="choose-color"></span></div>';
    //html += '<span class="fields-row apply-button" title="Apply"><span>Apply</span></span>';
    jQuery(".video-editor-editing-options-col.text").html(html);
    jQuery(".video-editor-editing-options-col.text").attr("data-index", id);
    if (data !== undefined) {
      jQuery("#choose-font-size").val(parseInt(data["font-size-real"]));
      jQuery("input[name=choose-bold]").attr("checked", data["font-bold"]);
      if (data["font-bold"]) {
        jQuery(".fields-row.font-bold").addClass("active");
      }
      jQuery("input[name=choose-italic]").attr("checked", data["font-italic"]);
      if (data["font-italic"]) {
        jQuery(".fields-row.font-italic").addClass("active");
      }
      var alignElem = jQuery(".fields-row.font-align span");
      var colorElem = jQuery(".fields-row.font-color span");
      for (let i = 0; i < alignElem.length; i++) {
        var element = alignElem[i];
        var elemVal = jQuery(element).find("input").val();
        if (elemVal == data["font-align"]) {
          jQuery(element).find("input").attr("checked", true);
          jQuery(element).addClass("active");
        }
      }
      for (let j = 0; j < colorElem.length; j++) {
        var element = colorElem[j];
        var elemVal = jQuery(element).find("input").val();
        if (elemVal == data["font-color"]) {
          jQuery(element).find("input").attr("checked", true);
          jQuery(element).addClass("active");
        }
      }
    }

    jQuery("#video-editor-editing-options").show();
    jQuery("#choose-font-size").unbind("input");
    jQuery("#choose-font-size").on("input", (e) => {
      this.activeStateToggle("same", e.currentTarget);
      this.setVideoEditOptions(id, "text");
      this.applyVideoEditFront(id, "text");
    });

    jQuery(".fields-row.font-bold").unbind("click");
    jQuery(".fields-row.font-bold").on("click", (e) => {
      this.activeStateToggle("same", e.currentTarget);
      this.setVideoEditOptions(id, "text");
      this.applyVideoEditFront(id, "text");
    });

    jQuery(".fields-row.font-italic").unbind("click");
    jQuery(".fields-row.font-italic").on("click", (e) => {
      this.activeStateToggle("same", e.currentTarget);
      this.setVideoEditOptions(id, "text");
      this.applyVideoEditFront(id, "text");
    });

    jQuery(".fields-row.font-align span").unbind("click");
    jQuery(".fields-row.font-align span").on("click", (e) => {
      this.activeStateToggle(
        "parent",
        e.currentTarget,
        jQuery(".fields-row.font-align span")
      );
      this.setVideoEditOptions(id, "text");
      this.applyVideoEditFront(id, "text");
    });

    jQuery(".fields-row.font-color span").unbind("click");
    jQuery(".fields-row.font-color span").on("click", (e) => {
      this.activeStateToggle(
        "parent",
        e.currentTarget,
        jQuery(".fields-row.font-color span")
      );
      this.setVideoEditOptions(id, "text");
      this.applyVideoEditFront(id, "text");
    });
  }

  editingOptionsImage(id, data) {
    var html = "";
    jQuery(".video-editor-editing-options-col.text").html(html);
    jQuery(".video-editor-editing-options-col.blur").html(html);
    jQuery(".video-editor-editing-options-col.image").html(html);
    jQuery(".video-editor-editing-options-col.speed").html(html);
    html += '<span class="fields-row"><label>Image Options: </label></span>';
    //html += '<span class="fields-row image-shadow" title="Shadow"><input id="image-shadow" type="number" min="1" max="5" value="3"></span>';
    html +=
      '<span class="fields-row image-opacity" title="Opacity"><input id="image-opacity" type="range" class="form-range form-control border-0" min="0" max="5" value="3"></span>';
    //html += '<span class="fields-row apply-button" title="Apply"><span>Apply</span></span>';
    jQuery(".video-editor-editing-options-col.image").html(html);
    if (data !== undefined) {
      jQuery("#image-opacity").val(data["img-opacity"]);
      jQuery("#image-shadow").val(data["img-shadow"]);
    }
    jQuery(".video-editor-editing-options-col.image").attr("data-index", id);
    jQuery("#video-editor-editing-options").show();
    jQuery(".image-opacity").unbind("change");
    jQuery(".image-opacity").on("change", () => {
      this.setVideoEditOptions(id, "image");
      this.applyVideoEditFront(id, "image");
    });
  }

  editingOptionsBlur(id, data) {
    var html = "";
    jQuery(".video-editor-editing-options-col.text").html(html);
    jQuery(".video-editor-editing-options-col.blur").html(html);
    jQuery(".video-editor-editing-options-col.image").html(html);
    jQuery(".video-editor-editing-options-col.speed").html(html);
    html += '<span class="fields-row"><label>Blur Options: </label></span>';
    html +=
      '<span class="fields-row blur-intensity" title="Blur Intensity"><input id="blur-intensity" class="form-range form-control border-0" type="range" min="1" max="3" value="3"></span>';
    //html += '<span class="fields-row apply-button" title="Apply"><span>Apply</span></span>';
    jQuery(".video-editor-editing-options-col.blur").html(html);
    if (data !== undefined) {
      jQuery("#blur-intensity").val(data["intensity"]);
    }
    jQuery(".video-editor-editing-options-col.blur").attr("data-index", id);
    jQuery("#video-editor-editing-options").show();
    jQuery(".blur-intensity").unbind("change");
    jQuery(".blur-intensity").on("change", () => {
      this.setVideoEditOptions(id, "blur");
      this.applyVideoEditFront(id, "blur");
    });
  }

  editingOptionsSpeed(id, data) {
    var html = "";
    jQuery(".video-editor-editing-options-col.text").html(html);
    jQuery(".video-editor-editing-options-col.blur").html(html);
    jQuery(".video-editor-editing-options-col.image").html(html);
    jQuery(".video-editor-editing-options-col.speed").html(html);
    html += '<span class="fields-row"><label>Speed Options: </label></span>';
    // html +=
    //   '<span class="fields-row speed-intensity" title="Speed"><select id="quix-video-speed"><option value="1.0">- Select -</option><option value="2.0">0.5x</option><option value="0.5">2x</option><option value="0.3">3x</option></select></span>';
    //html += '<span class="fields-row apply-button" title="Apply"><span>Apply</span></span>';
    html +=
      '<span class="fields-row speed-intensity" title="Speed"><select id="quix-video-speed"><option value="2">0.5x</option><option selected value="1.0">Normal</option><option value="0.5">2x</option><option value=" 0.33">3x</option><option value=" 0.2">5x</option></select></span>';
    jQuery(".video-editor-editing-options-col.speed").html(html);
    if (data !== undefined) {
      jQuery("#quix-video-speed").val(data["speed"]);
    }
    jQuery(".video-editor-editing-options-col.speed").attr("data-index", id);
    jQuery("#video-editor-editing-options").show();
    jQuery("#quix-video-speed").unbind("change");
  }

  urlToFile = async (url, fileName, mimeType) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: mimeType });
    return file;
  };

  activeStateToggle(type, obj, elem) {
    if (type == "parent") {
      for (let i = 0; i < elem.length; i++) {
        jQuery(elem[i]).removeClass("active");
      }
      if (!jQuery(obj).hasClass("active")) {
        jQuery(obj).addClass("active");
      }
    } else {
      if (!jQuery(obj).hasClass("active")) {
        jQuery(obj).addClass("active");
      } else {
        jQuery(obj).removeClass("active");
      }
    }
  }

  applyVideoEditFront(id, type) {
    var data = this.videoEditOptionItems[id - 1];
    if (data !== undefined) {
      if (type == "text") {
        var elem = jQuery("#int-text-box-" + id);
        var textElem = jQuery(elem).find("#textarea");
        var fontSize = data["font-size"] + "px";
        var fontColor = data["font-color"];
        var fontBold = data["font-bold"];
        var fontWeight = 400;
        if (fontBold) {
          fontWeight = 800;
        }
        var fontItalic = data["font-italic"];
        var fontStyle = "normal";
        if (fontItalic) {
          fontStyle = "italic";
        }
        var fontAlign = data["font-align"];
        textElem.css({
          "font-size": fontSize,
          color: fontColor,
          "text-align": fontAlign,
          "font-style": fontStyle,
          "font-weight": fontWeight,
        });
        this.adjustTextAreaAutomatically();
      } else if (type == "blur") {
        var elem = jQuery("#int-blur-box-" + id);
        var blurElem = jQuery(elem).find(".editor-blur-box-inner");
        var intensity = "blur(" + data["intensity"] * 3 + "px)";
        blurElem.css({ "backdrop-filter": intensity });
      } else if (type == "image") {
        var elem = jQuery("#int-image-upload-" + id);
        var imageElem = jQuery(elem).find("img");
        var imgOpacity = data["img-opacity"];
        imgOpacity = ((imgOpacity / 5) * 100) / 100;
        var imgShadow = data["img-shadow"] * 2;
        imgShadow = "0px 0px " + imgShadow + "px #706f6f";
        imageElem.css({ opacity: imgOpacity });
        elem.css({ "box-shadow": imgShadow });
      }
    }
  }

  setVideoEditOptions(id, type) {
    if (id !== "") {
      var settings = {};
      if (type == "text") {
        var fontSize = jQuery("#choose-font-size").val();
        var fontBold = (fontStyle = false);
        if (jQuery("input[name=choose-bold]").is(":checked")) {
          var fontBold = true;
        }
        if (jQuery("input[name=choose-italic]").is(":checked")) {
          var fontStyle = true;
        }
        var fontAlign = jQuery("input[name=choose-align]:checked").val();
        var fontColor = jQuery("input[name=choose-color]:checked").val();
        settings = {
          "font-size-real": fontSize,
          "font-size": fontSize,
          "font-bold": fontBold,
          "font-italic": fontStyle,
          "font-color": fontColor,
          "font-align": fontAlign,
        };
      } else if (type == "blur") {
        var blurIntensity = jQuery("#blur-intensity").val();
        settings = {
          intensity: blurIntensity,
        };
      } else if (type == "image") {
        var imageShadow = jQuery("#image-shadow").val();
        var imageOpacity = jQuery("#image-opacity").val();
        settings = {
          "img-shadow": imageShadow,
          "img-opacity": imageOpacity,
        };
      }
      this.videoEditOptionItems[id - 1] = settings;
    }
  }

  editSettingsLoad(obj) {
    var index = jQuery(obj).attr("data-index");
    var editData = this.videoEditOptionItems[index - 1];
    if (jQuery(obj).hasClass("text")) {
      this.editingOptionsText(index, editData);
    } else if (jQuery(obj).hasClass("blur")) {
      this.editingOptionsBlur(index, editData);
    } else if (jQuery(obj).hasClass("image")) {
      this.editingOptionsImage(index, editData);
    }
  }

  rgbToHex(rgb) {
    var strSplit = rgb.split("rgb(");
    var strSplit2 = strSplit[1].split(")");
    var strSplit3 = strSplit2[0].split(",");
    var r = strSplit3[0];
    var g = strSplit3[1];
    var b = strSplit3[2];
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  getURLParameter(qs, name) {
    var pattern = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(pattern);
    var res = regex.exec(qs);
    if (res == null) return "";
    else return res[1];
  }

  // getHardWrappedText() {
  //   if (window.location.href !== window.location.href) return;
  //   if (document.getElementById("ifrm")) {
  //     var frm_url = document.getElementById("ifrm").contentDocument.URL;
  //     // if (frm_url.indexOf('http') < 0) return;
  //     var text = unescape(
  //       this.getURLParameter(
  //         document.getElementById("ifrm").contentDocument.URL,
  //         "bar"
  //       )
  //     ).replace(/\+/g, " ");
  //     var arrTxt = text.split(/\r?\n|\r|\n/g);
  //     this.videoEditTextStrings.push(arrTxt);
  //     var forms = jQuery("form");
  //     this.formsSubmitted++;
  //     if (this.formsSubmitted < forms.length) {
  //       jQuery(forms[this.formsSubmitted]).submit();
  //     }
  //     else {
  //       this.editVideo();
  //     }
  //   }
  // }

  async optimizeVideoContent(file, width, height, callback) {
    var resizedImage;
    if (file.type.match(/image.*/)) {
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var can = document.createElement("canvas");
          can.width = width;
          can.height = height;
          can.getContext("2d").drawImage(image, 0, 0, width, height);
          resizedImage = can.toDataURL("image/png");
          resizedImage = new File([resizedImage], file.name, {
            type: "image/png",
          });
          callback(resizedImage);
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  failureMessagePopup = (text, description, closeTime) => {
    let padTop = "30px";
    let isClosetime = !closeTime ? 5000 : closeTime;
    let html =
      '<div id="failure-popup-wrapper">\n\
          <div class="failure-popup">\n\
          <div class="failure-heading" style="padding-top: ' +
      padTop +
      ';">\n\
          <img src="' +
      failureImg +
      '"/>\n\
          <span class="failure-message">' +
      text +
      '</span>\n\
          <span class="failure-description">' +
      description +
      "</span>\n\
          </div>\n\
          </div>\n\
          </div>";
    jQuery("body").append(html);
    setTimeout(() => {
      jQuery("#failure-popup-wrapper").remove();
    }, isClosetime);
  };
}
